import React, { useReducer } from "react";
import { ADMIN_PANEL_USER_ACTION_TYPES } from "../actions/adminPanelUserActions";
import {
  IAdminPanelUser,
  IAdminPanelUserAction,
  IAdminPanelUserModel,
} from "../interface/adminPanelUserInterface";

const defaultState: IAdminPanelUser[] = [] as IAdminPanelUser[];

const reducer = (
  state: IAdminPanelUser[],
  action: IAdminPanelUserAction
): IAdminPanelUser[] => {
  switch (action.type) {
    case ADMIN_PANEL_USER_ACTION_TYPES.GET_USERS:
      return action.payload;
    case ADMIN_PANEL_USER_ACTION_TYPES.ADD_USER:
      return [...state, action.payload];
    case ADMIN_PANEL_USER_ACTION_TYPES.UPDATE_USER:
      return state.map((adminUser) => {
        if (adminUser.id === action.payload.id) {
          // only update field if it is not null
          return {
            ...adminUser,
            first_name: action.payload.first_name ?? adminUser.first_name,
            last_name: action.payload.last_name ?? adminUser.last_name,
            phone_number: action.payload.phone_number ?? adminUser.phone_number,
            profile_picture_url:
              action.payload.profile_picture_url ??
              adminUser.profile_picture_url,
            role: action.payload.role ?? adminUser.role,
            // we need to check property for dispatcher id as we allow null as a value
            dispatcher_id: Object.prototype.hasOwnProperty.call(
              action.payload,
              "dispatcher_id"
            )
              ? (action.payload.dispatcher_id as number | null)
              : adminUser.dispatcher_id,
          };
        }
        return adminUser;
      });
    default:
      return state;
  }
};
export const AdminPanelUserContext = React.createContext(
  {} as IAdminPanelUserModel
);

export const AdminPanelUserContextProvider: React.FC = (props) => {
  const [adminPanelUserState, adminPanelUserDispatch] = useReducer(
    reducer,
    defaultState
  );

  return (
    <AdminPanelUserContext.Provider
      value={{ adminPanelUserState, adminPanelUserDispatch }}
    >
      {props.children}
    </AdminPanelUserContext.Provider>
  );
};
