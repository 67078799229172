import { useContext, useEffect, useState } from 'react';
import WorkerAccordion from './WorkerAccordion';
import { WorkerContext } from '../context/workerContext';
import { CheckinType } from '../interface/checkinInterface';
import { IWorker } from '../interface/workerInterface';


export default function WorkerPanel() {
  const [safeWorkers, setSafeWorkers] = useState([] as IWorker[]);
  const [emergencyWorkers, setEmergencyWorkers] = useState([] as IWorker[]);
  const [lifecheckWorkers, setLifecheckWorkers] = useState([] as IWorker[]);
  const { workerState } = useContext(WorkerContext)

  useEffect(() => {
    // go through worker state and sort based on current worker checkin 
    const currentSafeWorkers = []
    const currentEmergencyWorkers = []
    const currentLifeCheckWorkers = []
    for (const worker of workerState) {
      if (worker.last_checkin.checkin_type === CheckinType.safe) {
        currentSafeWorkers.push(worker)
      } else if (worker.last_checkin.checkin_type === CheckinType.emergency) {
        currentEmergencyWorkers.push(worker)
      } else if (worker.last_checkin.checkin_type === CheckinType.life_check) {
        currentLifeCheckWorkers.push(worker)
      }
    }
    setSafeWorkers(currentSafeWorkers)
    setEmergencyWorkers(currentEmergencyWorkers)
    setLifecheckWorkers(currentLifeCheckWorkers)
  }, [workerState])
  return (
    <>
      {emergencyWorkers.length > 0 ? <WorkerAccordion workers={emergencyWorkers} title="Emergency" /> : (<></>)}
      {lifecheckWorkers.length > 0 ? <WorkerAccordion workers={lifecheckWorkers} title="Life Check" /> : (<></>)}
      {safeWorkers.length > 0 ? <WorkerAccordion workers={safeWorkers} title="Safe" /> : (<></>)}
    </>
  );
}
