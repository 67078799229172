import { IAdminPanelGroupAction } from "../interface/adminPanelGroupInterface";
import { APIMethods, makeAPICall } from "../utils";

export enum ADMIN_PANEL_GROUP_ACTION_TYPES {
  GET_GROUPS = "GET_GROUPS",
  ADD_GROUP = "ADD_GROUP",
  DELETE_GROUP_BY_ID = "DELETE_GROUP_BY_ID",
}

/**
 * This function returns the groups of the organization
 */
export const getAdminPanelGroups = async (): Promise<
  IAdminPanelGroupAction
> => {
  const groupData = await makeAPICall("/admin/groups", APIMethods.GET, null);
  return {
    type: ADMIN_PANEL_GROUP_ACTION_TYPES.GET_GROUPS,
    payload: groupData.data,
  };
};
