import React, { useReducer, useEffect, useState } from 'react'
import { WORKER_ACTION_TYPES } from '../actions/workerActions';
import { IWorker, IWorkerAction, IWorkerModel } from '../interface/workerInterface';


const defaultState: IWorker[] = [] as IWorker[]

const reducer = (
    state: IWorker[],
    action: IWorkerAction
): IWorker[] => {
    switch (action.type) {
        case WORKER_ACTION_TYPES.GET_MONITORED_WORKERS_FROM_GROUP_ID:
            return action.payload
        // updates the worker from checkin
        case WORKER_ACTION_TYPES.UPDATE_MONITORED_USER_CHECKIN:
            return state.map((item) => {
                if (item.user_id === action.payload.user_id && item.group_id === action.payload.group_id) {
                    const checkinToUpdate = {
                        ...item,
                        last_checkin: {
                            ...item.last_checkin,
                            latitude: action.payload.latitude,
                            longitude: action.payload.longitude,
                            ttl: action.payload.ttl,
                            checkin_type: action.payload.checkin_type,
                            date_created: action.payload.date_created
                        }

                    };
                    return checkinToUpdate;
                }
                return item;
            });
        case WORKER_ACTION_TYPES.CREATE_MONITORED_USER:
            return [...state, {
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                ttl: action.payload.ttl,
                group_id: action.payload.group_id,
                user_id: action.payload.user_id,
                email: action.payload.email,
                last_checkin: action.payload.last_checkin,
                organization_id: action.payload.organization_id,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                phone_number: action.payload.phone_number,
                company: action.payload.company,
                profile_picture_url: action.payload.profile_picture_url
            }]
        case WORKER_ACTION_TYPES.DELETE_MONITORED_USER:
            return state.filter(item => (item.user_id !== action.payload.user_id && item.group_id !== action.payload.group_id))
        case WORKER_ACTION_TYPES.UPDATE_MONITORED_USER:
            return state.map((item) => {
                if (item.user_id === action.payload.user_id && item.group_id === action.payload.group_id) {
                    return action.payload;
                }
                return item
            });
        default:
            return state;
    }
};
export const WorkerContext = React.createContext({} as IWorkerModel);

export const WorkerContextProvider: React.FC = props => {
    const [workerState, workerDispatch] = useReducer(reducer, defaultState);
    const [focusedWorker, setFocusedWorker] = useState({} as IWorker)

    // Initializes user data for app
    useEffect(() => {
    }, [])
    return (
        <WorkerContext.Provider value={{ workerState, workerDispatch, focusedWorker, setFocusedWorker }}>
            {props.children}
        </WorkerContext.Provider>
    );
};

