import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
type ProfilePictureAvatarProps = {
    profile_picture_url: string | null
}
export default function ProfilePictureAvatar({ profile_picture_url }: ProfilePictureAvatarProps) {
    return (
        <>
            {
                profile_picture_url ? <Avatar
                    src={profile_picture_url}
                /> :
                    <Avatar>
                        <AccountCircleIcon fontSize="large" />
                    </Avatar>
            }
        </>
    );
}
