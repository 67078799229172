import { useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

function Login() {
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log(data)
          break;
        case 'cognitoHostedUI':
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
  }, []);


  return (
    <div>
      <button onClick={() => Auth.federatedSignIn()}>Federated Sign In</button>
    </div>
  );
}

export default Login;