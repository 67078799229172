import React, { useReducer, } from 'react'
import { USER_ACTION_TYPES } from '../actions/userActions';
import { IUser, IUserAction, IUserModel, UserRole } from '../interface/userInterface';



const defaultState: IUser = {} as IUser

const reducer = (
    state: IUser,
    action: IUserAction
): IUser => {
    switch (action.type) {
        case USER_ACTION_TYPES.GET_USER_DATA:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
export const UserContext = React.createContext({} as IUserModel);

export const UserContextProvider: React.FC = props => {
    const [userState, userDispatch] = useReducer(reducer, defaultState);

    const isUserAdmin = () => {
        return [UserRole.ADMIN, UserRole.SUPER_ADMIN].indexOf(userState.role) > -1
    }

    const isUserSuperAdmin = () => {
        return userState.role === UserRole.SUPER_ADMIN
    }

    return (
        <UserContext.Provider value={{ userState, userDispatch, isUserAdmin, isUserSuperAdmin }}>
            {props.children}
        </UserContext.Provider>
    );
};

