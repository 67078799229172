import React, { useState, useContext, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { IAdminPanelUser } from "../interface/adminPanelUserInterface";
import { GroupStatusEnum } from "../interface/groupInterface";
import { AdminPanelUserContext } from "../context/adminPanelUserContext";
import { AdminPanelGroupContext } from "../context/adminPanelGroupContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import { isPositiveInteger, makeAPICall, APIMethods } from "../utils";
import {
  IAdminPanelGroupCreate,
  IAdminPanelGroup,
} from "../interface/adminPanelGroupInterface";
import { ADMIN_PANEL_GROUP_ACTION_TYPES } from "../actions/adminPanelGroupActions";

import GroupTransferList, {
  TransferListItemProps,
} from "../components/GroupTransferList";
const FOUR_SECONDS_IN_MILLIS = 4000;
const DESCRIPTION_CHAR_LENGTH = 250;
const StyledDialog = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButtonGrid = styled(Grid)`
  text-align: right;
`;

const StyledFormControl = styled(FormControl)`
  &&& {
    margin-top: 15px;
  }
`;

const HalfFormControl = styled(FormControl)`
  &&& {
    display: flex;
    margin-top: 15px;
    flex-direction: row;

    .MuiFormControl-root:nth-child(2) {
      margin-left: 2%;
    }
  }
`;

const TTLField = styled(TextField)`
  width: 49%;
`;

const FormHeader = styled(Typography)`
  &&& {
    margin-bottom: 13px;
  }
`;

export default function CreateGroupModal() {
  const [open, setOpen] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [groupNameError, setGroupNameError] = useState<string>("");
  const [ttlMinutes, setTTLMinutes] = useState<number>(0);
  const [ttlMinutesError, setTTLMinutesError] = useState<string>("");
  const [ttlSeconds, setTTLSeconds] = useState<number>(0);
  const [ttlSecondsError, setTTLSecondsError] = useState<string>("");
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [groupDescriptionError, setGroupDescriptionError] = useState<boolean>(
    false
  );
  const [groupDescriptionHelperText, setGroupDescriptionHelperText] = useState<
    string
  >("");
  const [groupDescription, setGroupDescription] = useState<string>("");
  const [selectedDispatchers, setSelectedDispatchers] = useState<
    TransferListItemProps[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<TransferListItemProps[]>(
    []
  );
  const { adminPanelUserState } = useContext(AdminPanelUserContext);
  const { adminPanelGroupDispatch } = useContext(AdminPanelGroupContext);
  const [userOptions, setUserOptions] = useState([] as TransferListItemProps[]);
  const [dispatcherOptions, setDispatcherOptions] = useState(
    [] as TransferListItemProps[]
  );

  const [createError, setCreateError] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState<boolean>(false);
  useEffect(() => {
    const dispatchersOptionsAvailable = adminPanelUserState
      .filter((user: IAdminPanelUser) => user.dispatcher_id)
      .map((user: IAdminPanelUser) => {
        return {
          id: user.dispatcher_id as number,
          name: `${user.first_name} ${user.last_name}`,
        };
      });
    const userOptionsAvailable = adminPanelUserState.map(
      (user: IAdminPanelUser) => {
        return { id: user.id, name: `${user.first_name} ${user.last_name}` };
      }
    );
    setUserOptions(userOptionsAvailable);
    setDispatcherOptions(dispatchersOptionsAvailable);
  }, [adminPanelUserState]);
  const clearModal = () => {
    setGroupName("");
    setSelectedDispatchers([]);
    setSelectedUsers([]);
    setIsCreating(false);
    setCreateError("");
    setTTLMinutesError("");
    setTTLSecondsError("");
    setGroupNameError("");
    setTTLMinutes(0);
    setTTLSeconds(0);
    setGroupDescription("");
    setGroupDescriptionHelperText("");
    setGroupDescriptionError(false);
    setIsConfirmCloseOpen(false);
  };
  const validateGroupDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const description = event.target.value;
    setGroupDescription(description);
    setGroupDescriptionHelperText(
      `${description.length}/${DESCRIPTION_CHAR_LENGTH} characters`
    );
    if (description.length > DESCRIPTION_CHAR_LENGTH) {
      setGroupDescriptionError(true);
      return;
    }
    setGroupDescriptionError(false);
  };
  /**
   * Validate first name of user
   */

  const validateGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name.length < 2) {
      setGroupNameError("Must be a valid name at least 2 characters long");
    } else {
      setGroupNameError("");
    }
    setGroupName(name);
  };
  // TTL value must be higher then 90 seconds
  const isValidTTLValue = () => {
    const ttlValue = ttlMinutes * 60 + ttlSeconds;
    if (ttlValue < 60) {
      return false;
    }
    return true;
  };
  const validateTTLMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minutes = Number(event.target.value);
    if (!isPositiveInteger(minutes)) {
      setTTLMinutesError("Please enter a valid positive number");
    } else {
      setTTLMinutesError("");
    }
    setTTLMinutes(minutes);
  };
  const validateTTLSeconds = (event: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = Number(event.target.value);
    if (!isPositiveInteger(seconds)) {
      setTTLSecondsError("Please enter a valid positive number");
    } else {
      setTTLSecondsError("");
    }
    setTTLSeconds(seconds);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearModal();
    setOpen(false);
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const createGroup = async (event: any) => {
    event.preventDefault();
    setIsCreating(true);
    setCreateError("");
    if (!isValidTTLValue()) {
      setCreateError("Your check-in window must be longer then 90 seconds");
      setIsCreating(false);
      return false;
    }
    if (groupNameError || ttlMinutesError || ttlSecondsError) {
      setIsCreating(false);
      return false;
    }
    const ttlValue = ttlMinutes * 60 + ttlSeconds;
    const groupToCreate: IAdminPanelGroupCreate = {
      name: groupName,
      ttl: ttlValue,
      description: groupDescription,
      status: GroupStatusEnum.ACTIVE,
    };
    const createdGroup = await makeAPICall(
      "/groups",
      APIMethods.POST,
      groupToCreate
    )
      .then((res) => res)
      .catch((err) => err);
    if (createdGroup.isAxiosError) {
      setCreateError(createdGroup.response.data.detail);
      setIsCreating(false);
      return false;
    }
    const createDispatcherUserRelationshipRequests: Promise<any>[] = [];
    // append all all dispatcher group relations
    for (const dispatcher of selectedDispatchers) {
      createDispatcherUserRelationshipRequests.push(
        makeAPICall(`/admin/dispatcher_groups`, APIMethods.POST, {
          group_id: createdGroup.data.id,
          dispatcher_id: dispatcher.id,
        })
      );
    }
    for (const user of selectedUsers) {
      createDispatcherUserRelationshipRequests.push(
        makeAPICall(`/admin/user_groups`, APIMethods.POST, {
          group_id: createdGroup.data.id,
          user_id: user.id,
        })
      );
    }
    const returnCreateDispatcherUserRelationships = await Promise.all(
      createDispatcherUserRelationshipRequests
    )
      .then((res) => res)
      .catch((err) => err);
    // check for errors
    for (const returnCreateDispatcherUserRelationship of returnCreateDispatcherUserRelationships) {
      if (returnCreateDispatcherUserRelationship.isAxiosError) {
        setCreateError(`Couldnt create user/dispatcher group relationship`);
        throw new Error("Create relationship failed");
      }
    }
    const newGroupPayload: IAdminPanelGroup = {
      id: createdGroup.data.id,
      ttl: createdGroup.data.ttl,
      user_ids: selectedUsers.map((user) => user.id),
      dispatcher_ids: selectedDispatchers.map((dispatcher) => dispatcher.id),
      description: groupDescription,
      date_created: createdGroup.data.date_created,
      last_updated: createdGroup.data.last_updated,
      name: groupName,
      status: GroupStatusEnum.ACTIVE,
    };
    // update admin group state
    adminPanelGroupDispatch({
      type: ADMIN_PANEL_GROUP_ACTION_TYPES.ADD_GROUP,
      payload: newGroupPayload,
    });
    setOpen(false);
    setShowSnackBar(true);
    clearModal();
  };
  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={FOUR_SECONDS_IN_MILLIS}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          Group Created Successfully
        </Alert>
      </Snackbar>
      <Dialog
        open={isConfirmCloseOpen}
        onClose={() => setIsConfirmCloseOpen(false)}
        BackdropComponent={Backdrop}
      >
        <DialogTitle id="scroll-dialog-title">Create Group</DialogTitle>
        <DialogContent>
          Are you sure you want to stop creating a new group
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsConfirmCloseOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <StyledButtonGrid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Create Group
        </Button>
      </StyledButtonGrid>
      <StyledDialog
        open={open}
        onClose={() => setIsConfirmCloseOpen(true)}
        closeAfterTransition
        scroll="paper"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <form onSubmit={createGroup}>
          <DialogTitle id="scroll-dialog-title">Create Group</DialogTitle>
          <DialogContent dividers>
            <div>
              <StyledFormControl fullWidth>
                <TextField
                  error={!!groupNameError}
                  helperText={groupNameError}
                  required
                  value={groupName}
                  onChange={validateGroupName}
                  variant="outlined"
                  label="Group Name"
                />
              </StyledFormControl>
              <HalfFormControl fullWidth>
                <TTLField
                  error={!!ttlMinutesError}
                  helperText={ttlMinutesError}
                  label="# Minutes Before Checkin"
                  onChange={validateTTLMinutes}
                  value={ttlMinutes}
                  variant="outlined"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Minutes</InputAdornment>
                    ),
                  }}
                />
                <TTLField
                  error={!!ttlSecondsError}
                  helperText={ttlSecondsError}
                  label="# Seconds Before Checkin"
                  onChange={validateTTLSeconds}
                  type="number"
                  value={ttlSeconds}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Seconds</InputAdornment>
                    ),
                  }}
                />
              </HalfFormControl>
              <StyledFormControl fullWidth>
                <TextField
                  error={!!groupDescriptionError}
                  helperText={groupDescriptionHelperText}
                  value={groupDescription}
                  onChange={validateGroupDescription}
                  variant="outlined"
                  label="Short Description"
                  multiline
                  placeholder="Max 250 characters"
                  rowsMax={4}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <FormHeader variant="body1">
                  Assign Dispatchers to the Group
                </FormHeader>
                <GroupTransferList
                  selection={dispatcherOptions}
                  selected={selectedDispatchers}
                  handleSelect={setSelectedDispatchers}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <FormHeader variant="body1">Assign User To Group</FormHeader>
                <GroupTransferList
                  selection={userOptions}
                  selected={selectedUsers}
                  handleSelect={setSelectedUsers}
                />
              </StyledFormControl>
            </div>
          </DialogContent>
          <DialogActions>
            {createError && <Alert severity="error">{createError}</Alert>}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isCreating}
            >
              {isCreating ? "Creating" : "Create Group"}
            </Button>
          </DialogActions>
        </form>
      </StyledDialog>
    </>
  );
}
