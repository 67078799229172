import { Link } from 'react-router-dom';

function Settings() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <Link to="/">Home</Link>

            Edit <code>src/App.tsx</code> and save to reload.
          </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
          </a>
      </header>
    </div>
  );
}

export default Settings;