import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import EmailIcon from "@material-ui/icons/Email";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { AdminPanelUserContext } from "../context/adminPanelUserContext";
import { IAdminPanelUser } from "../interface/adminPanelUserInterface";
import EditUserPanel from "./EditUserPanel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const ROW_HEIGHT = 53;
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: IAdminPanelUser[],
  comparator: (a: any, b: any) => number
) {
  const stabilizedThis = array.map(
    (el, index) => [el, index] as [IAdminPanelUser, number]
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IAdminPanelUser;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  { id: "last_name", numeric: false, disablePadding: true, label: "Last Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  {
    id: "is_signed_up",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dispatcher_id",
    numeric: false,
    disablePadding: false,
    label: "Dispatcher",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IAdminPanelUser
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof IAdminPanelUser) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "Select All Users" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

const StyledRoot = styled.div`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  marginbottom: 16px;
`;

const StyledTable = styled(Table)`
  min-width: 750px;
`;
const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const CapitalizedTableCell = styled(TableCell)`
  text-transform: capitalize;
`;

export default function AdminUserTable() {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IAdminPanelUser>("last_name");
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([] as IAdminPanelUser[]);
  const { adminPanelUserState } = useContext(AdminPanelUserContext);
  const [isEditUserPanelOpen, setIsEditUserPanelOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState({} as IAdminPanelUser);
  useEffect(() => {
    setRows(adminPanelUserState);
  }, [adminPanelUserState]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IAdminPanelUser
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: IAdminPanelUser) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const viewUserDetails = (event: React.MouseEvent<unknown>, id: number) => {
    const userTargeted = rows.filter((user) => user.id === id)[0];
    setUserToEdit(userTargeted);
    setIsEditUserPanelOpen(true);
  };
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    // stops viewUserDetails assigned to table row f
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);

    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const closeEditUserPanel = () => {
    setUserToEdit({} as IAdminPanelUser);
    setIsEditUserPanelOpen(false);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (rows.length - page) * rowsPerPage);

  const renderRows = () => {
    return stableSort(rows, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <StyledTableRow
            hover
            onClick={(event) => viewUserDetails(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            key={row.id}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                onClick={(event) => handleClick(event, row.id)}
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
              />
            </TableCell>
            <CapitalizedTableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
            >
              {row.first_name}
            </CapitalizedTableCell>
            <CapitalizedTableCell align="left">
              {row.last_name}
            </CapitalizedTableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.phone_number}</TableCell>
            <CapitalizedTableCell align="left">{row.role}</CapitalizedTableCell>
            <TableCell align="left">
              {row.is_signed_up ? (
                <Chip
                  label="Active"
                  color="primary"
                  size="small"
                  icon={<DoneIcon />}
                />
              ) : (
                <Chip label="Invited" size="small" icon={<EmailIcon />} />
              )}
            </TableCell>
            <TableCell align="left">
              {row.dispatcher_id ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <CancelIcon color="error" />
              )}
            </TableCell>
          </StyledTableRow>
        );
      });
  };
  return (
    <StyledRoot>
      <EditUserPanel
        isEditUserPanelOpen={isEditUserPanelOpen}
        userToEdit={userToEdit}
        closeEditUserPanel={closeEditUserPanel}
      />
      <StyledPaper>
        <TableContainer>
          <StyledTable
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {renderRows()}
              {emptyRows > 0 && (
                <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </StyledRoot>
  );
}
