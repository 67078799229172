import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";

const StyledList = styled(List)`
  width: 200px;
  height: 230px;
  overflow: auto;
`;

const StyledButton = styled(Button)`
  margin: 4px 0;,
`;

const StyledRoot = styled(Grid)`
  margin: auto;
`;

export interface TransferListItemProps {
  id: number;
  name: string;
}
interface GroupTransferListProps {
  selection: TransferListItemProps[];
  selected: TransferListItemProps[];
  handleSelect: (items: TransferListItemProps[]) => void;
}

export default function GroupTransferList({
  selection,
  selected,
  handleSelect,
}: GroupTransferListProps) {
  const [checked, setChecked] = React.useState<TransferListItemProps[]>([]);
  const [left, setLeft] = React.useState<TransferListItemProps[]>([]);
  const [right, setRight] = React.useState<TransferListItemProps[]>([]);

  useEffect(() => {
    if (left.length === 0 && right.length === 0) {
      setLeft(selection);
      setRight(selected);
    }
  }, [selection, selected, right.length, left.length]);
  const not = (a: TransferListItemProps[], b: TransferListItemProps[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };

  const intersection = (
    a: TransferListItemProps[],
    b: TransferListItemProps[]
  ) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  };

  const union = (a: TransferListItemProps[], b: TransferListItemProps[]) => {
    return [...a, ...not(b, a)];
  };
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: TransferListItemProps) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: TransferListItemProps[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: TransferListItemProps[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newSelected = right.concat(leftChecked);
    setRight(newSelected);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    handleSelect(newSelected);
  };

  const handleCheckedLeft = () => {
    const newUnSelect = left.concat(rightChecked);
    setLeft(newUnSelect);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    handleSelect(newUnSelect);
  };

  const customList = (
    title: React.ReactNode,
    items: TransferListItemProps[]
  ) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) > 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <StyledList dense role="list">
        {items.map((value: TransferListItemProps) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              dense
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </StyledList>
    </Card>
  );

  return (
    <StyledRoot container spacing={2} justify="center" alignItems="center">
      <Grid item>{customList("Select", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </StyledButton>
        </Grid>
      </Grid>
      <Grid item>{customList("Assigned", right)}</Grid>
    </StyledRoot>
  );
}
