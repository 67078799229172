import AdminSettingsNavDrawer, {
  AdminSettingsNavTypes,
} from "../components/AdminSettingsNavDrawer";
import CreateGroupModal from "../components/CreateGroupModal";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OrganizationGroupsGrid from "../components/OrganizationGroupsGrid";

const StyledRoot = styled.div`
  display: flex;
`;
const StyledMain = styled.main`
  flex-grow: 1;
  margin-top: 85px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
`;
export default function AdminOrganizationGroups() {
  return (
    <StyledRoot className="App">
      <AdminSettingsNavDrawer selected={AdminSettingsNavTypes.Groups} />
      <StyledMain>
        <Grid container spacing={1}>
          <CreateGroupModal />
          <Grid item xs={10}>
            <Typography variant="h5">Your Groups</Typography>
          </Grid>
        </Grid>
        <OrganizationGroupsGrid />
      </StyledMain>
    </StyledRoot>
  );
}
