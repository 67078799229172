import { IUserAction } from "../interface/userInterface";
import { makeAPICall, APIMethods } from "../utils"

export enum USER_ACTION_TYPES {
    GET_USER_DATA = "GET_USER_DATA",
}
/**
 * This function returns the logged in user data 
 * @returns IUserAction 
 */
export const getUserData = async (): Promise<IUserAction> => {
    const userData = await makeAPICall('/users', APIMethods.GET, null)
    return {
        type: USER_ACTION_TYPES.GET_USER_DATA,
        payload: userData.data[0]
    }
}

