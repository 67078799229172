let AWSSettings = { awsauth: {}, awsconfig: {} };

const local = {
  awsauth: {
    oauth: {
      domain: "dev.auth.onguard.co",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "http://localhost:3000/login",
      responseType: "code",
    },
  },
  awsconfig: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_byDS6nyrr",
    aws_user_pools_web_client_id: "4kc3nc2fr5q7f5r0evbjo243nn",
    aws_appsync_graphqlEndpoint:
      "https://k6kehcurlfh75mhm6fbzsgpjte.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    redirectSignIn: "http://localhost:3000/login",
  },
};

const dev = {
  awsauth: {
    oauth: {
      domain: "dev.auth.onguard.co",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://dev.admin.onguard.co/login",
      responseType: "code",
    },
  },
  awsconfig: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_byDS6nyrr",
    aws_user_pools_web_client_id: "acv91phkulg068r3adji6q518",
    aws_appsync_graphqlEndpoint:
      "https://k6kehcurlfh75mhm6fbzsgpjte.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    redirectSignIn: "https://dev.admin.onguard.co/login",
  },
};
// set up config vars
if (process.env.REACT_APP_STAGE === "dev") {
  AWSSettings = dev;
} else {
  AWSSettings = local;
}

export default AWSSettings;
