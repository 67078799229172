import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { AdminPanelGroupContext } from "../context/adminPanelGroupContext";
import { IAdminPanelGroup } from "../interface/adminPanelGroupInterface";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { GroupStatusEnum } from "../interface/groupInterface";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { makeAPICall, APIMethods } from "../utils";
import { ADMIN_PANEL_GROUP_ACTION_TYPES } from "../actions/adminPanelGroupActions";

const THREE_SECONDS_IN_MILLIS = 3000;
const StyledGrid = styled(Grid)`
  &&& {
    margin-top: 20px;
  }
`;

const StyledDivider = styled(Divider)`
  &&& {
    margin-top: 20px;
  }
`;

const InformationDivContainer = styled.div`
  display: flex;
`;
const InformationDiv = styled.div`
  flex: auto;
  width: 50%;
  text-align: center;
  padding-top: 10px;
`;

const StyledCardContent = styled(CardContent)`
  text-align: center;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-top: -5px;
`;
const StyledIconButton = styled(IconButton)`
  &&& {
    padding: 3px;
  }
`;
export default function OrganizationGroupsGrid() {
  const { adminPanelGroupState } = useContext(AdminPanelGroupContext);
  const [groups, setGroups] = useState<IAdminPanelGroup[]>([]);
  const [groupsLoaded, setGroupsLoaded] = useState<boolean>(false);
  const [groupIdToDelete, setGroupIdToDelete] = useState<number>(0);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(
    false
  );
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string>("");
  const [showDeleteErrorSnackbar, setShowDeleteErrorSnackbar] = useState<
    boolean
  >(false);
  const { adminPanelGroupDispatch } = useContext(AdminPanelGroupContext);

  const loaded = useRef(false);

  const setDeleteGroupId = (e: React.MouseEvent<unknown>, groupId: number) => {
    // stop the onclick redirect to group view
    e.stopPropagation();
    setGroupIdToDelete(groupId);
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = async () => {
    setIsDeleting(true);
    setDeleteError("");
    const deletedGroup = await makeAPICall(
      `/admin/groups/${groupIdToDelete}`,
      APIMethods.DELETE,
      null
    )
      .then((res) => res)
      .catch((err) => err);
    if (deletedGroup.isAxiosError) {
      setDeleteError(deletedGroup.response.data.detail);
      setIsDeleting(false);
      setShowDeleteErrorSnackbar(true);
      return false;
    }
    // update admin group state
    adminPanelGroupDispatch({
      type: ADMIN_PANEL_GROUP_ACTION_TYPES.DELETE_GROUP_BY_ID,
      payload: groupIdToDelete,
    });
    setIsConfirmDeleteOpen(false);
    setShowDeleteSnackbar(true);
  };

  useEffect(() => {
    // dont fire on initial page load
    if (loaded.current) {
      setGroups(adminPanelGroupState);
      setGroupsLoaded(true);
    } else {
      loaded.current = true;
    }
  }, [adminPanelGroupState]);
  return (
    <>
      <Snackbar
        open={showDeleteErrorSnackbar}
        autoHideDuration={THREE_SECONDS_IN_MILLIS}
        onClose={() => setShowDeleteErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setShowDeleteErrorSnackbar(false)}
          severity="error"
        >
          {deleteError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showDeleteSnackbar}
        autoHideDuration={THREE_SECONDS_IN_MILLIS}
        onClose={() => setShowDeleteSnackbar(false)}
      >
        <Alert onClose={() => setShowDeleteSnackbar(false)} severity="success">
          Group Deleted Successfully
        </Alert>
      </Snackbar>
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        BackdropComponent={Backdrop}
      >
        <DialogTitle id="scroll-dialog-title">Create Group</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this group
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={isDeleting}
            onClick={confirmDelete}
          >
            {isDeleting ? "Deleting..." : "Yes"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isDeleting}
            onClick={() => setIsConfirmDeleteOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <StyledGrid container spacing={4}>
        {groups.length > 0 ? (
          <>
            {groups.map((group: IAdminPanelGroup) => {
              return (
                <Grid item xs={4} key={group.id}>
                  <Card onClick={() => console.log("clicked")}>
                    <CardActionArea>
                      <StyledCardContent>
                        <StyledButtonContainer>
                          <StyledIconButton
                            aria-label="settings"
                            onClick={(e) => setDeleteGroupId(e, group.id)}
                          >
                            <DeleteOutlineIcon />
                          </StyledIconButton>
                        </StyledButtonContainer>

                        <Typography gutterBottom variant="h5" component="h2">
                          {group.name}
                        </Typography>
                        {group.status === GroupStatusEnum.ACTIVE ? (
                          <Chip label="Active" color="primary" />
                        ) : (
                          <Chip label="Inactive" />
                        )}
                        <StyledDivider light />
                        <InformationDivContainer>
                          <InformationDiv>
                            <Typography variant="caption" color="textSecondary">
                              Users Assigned
                            </Typography>

                            <Typography variant="h5">
                              {group.user_ids.length}
                            </Typography>
                          </InformationDiv>
                          <InformationDiv>
                            <Typography variant="caption" color="textSecondary">
                              Dispatchers Assigned
                            </Typography>
                            <Typography variant="h5">
                              {group.dispatcher_ids.length}
                            </Typography>
                          </InformationDiv>
                        </InformationDivContainer>
                      </StyledCardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item xs={12}>
            {groupsLoaded ? (
              <Typography variant="h6">
                You have no groups in the organization, add one to get started
              </Typography>
            ) : (
              <Typography variant="h6">Loading...</Typography>
            )}
          </Grid>
        )}
      </StyledGrid>
    </>
  );
}
