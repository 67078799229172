import React, { useState, useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { UserContext } from '../context/userContext';
import IconButton from '@material-ui/core/IconButton';
import ProfilePictureAvatar from './ProfilePictureAvatar';
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`

export default function UserSettingsMenuDropdown() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { userState, isUserAdmin } = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(true)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(false)
    };


    return (
        <div>
            <IconButton aria-controls="user-settings-menu" aria-haspopup="true" onClick={handleClick}>
                <ProfilePictureAvatar profile_picture_url={userState.profile_picture_url} />
            </IconButton>
            <Menu
                id="user-settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={isOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>

                {isUserAdmin() ?
                    <MenuItem><StyledLink to="/admin/organization-users">Manage Organizations</StyledLink></MenuItem> : ""
                }
                <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
        </div>
    );
}