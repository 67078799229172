import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IWorker } from '../interface/workerInterface';
import WorkerList from './WorkerList'

type WorkerAccordianProps = {
    workers: IWorker[],
    title: string
}

type WorkerAccordianHeaderProps = {
    readonly type: string;
}

const StyledAccordion = styled(Accordion)`
&&&{
    margin:0px;
    width:500px

}
`

const StyledAccordionSummary = styled(AccordionSummary) <WorkerAccordianHeaderProps>`
&&&{
    min-height:0px;
    margin-top:5px;
    background: ${(props: any) => {
        if (props.type === "Emergency") {
            return "#f11c78"
        } else if (props.type === "Life Check") {
            return "#fff4c5"
        } else if (props.type === "Safe") {
            return "#c1ffd5"
        }
    }
    }

`

const StyledHeaderTypography = styled(Typography)`
font-weight:800;
font-size:1.1rem

`

const StyledAccordionDetails = styled(AccordionDetails)`
&&&{
    padding:0
}
`

/**This component is the accordian that shows the user all active/lifecheck/emergency users 
 * currently active
 */
export default function WorkerAccordion({ workers, title }: WorkerAccordianProps) {
    return (
        <StyledAccordion elevation={0} defaultExpanded>
            <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                type={title}
            >
                <StyledHeaderTypography >{workers.length} Users In {title} mode</StyledHeaderTypography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <WorkerList workers={workers} />
            </StyledAccordionDetails>
        </StyledAccordion>
    );
}
