import Typography from '@material-ui/core/Typography';
function Unauthorized() {
    return (
        <div className="App">
            <Typography variant="h4">
                You are unauthorized to enter this page
        </Typography>
        </div>
    );
}

export default Unauthorized;