import axios from "axios";
import { Auth } from "aws-amplify";
export enum ReactAppVersion {
  DEV = "dev",
  STAGE = "stage",
  PROD = "prod",
}
export enum APIMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const isPositiveInteger = (n: number) => {
  return Number.isInteger(n) && n >= 0;
};
/*
Makes api call to api server
@param url - url to make requiest to /users /dispatchers etc
@param method one one of get/post/put/delete
@param data to send

@returns data from api
*/

export const makeAPICall = async (
  url: string,
  method: APIMethods,
  data: any
) => {
  // set url of api based on version
  let API_URL;
  if (process.env.REACT_APP_STAGE === ReactAppVersion.PROD) {
    API_URL = `https://prod.api.onguard.co`;
  } else if (process.env.REACT_APP_STAGE === ReactAppVersion.STAGE) {
    API_URL = `https://stage.api.onguard.co`;
  } else {
    API_URL = `https://dev.api.onguard.co`;
  }
  const user = await Auth.currentSession();
  data = await axios({
    method: method,
    url: `${API_URL}${url}`,
    data: data,
    headers: {
      Authorization: `Bearer ${user.getAccessToken().getJwtToken()}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return data;
};
