import React, { useReducer } from 'react'
import { IGroup, IGroupAction, IGroupModel } from '../interface/groupInterface';
import { GROUP_ACTION_TYPES } from '../actions/groupActions';



const defaultState: IGroup[] = [] as IGroup[]

const reducer = (
    state: IGroup[],
    action: IGroupAction
): IGroup[] => {
    switch (action.type) {
        case GROUP_ACTION_TYPES.GET_DISPATCHER_GROUPS:
            return action.payload
        default:
            return state;
    }
};

export const GroupContext = React.createContext({} as IGroupModel);

export const GroupContextProvider: React.FC = props => {
    const [groupState, groupDispatch] = useReducer(reducer, defaultState);
    const getGroupFromId = (id: number) => {
        return groupState.filter((group: IGroup) => (group.id === id))[0]
    }
    // Initializes user data for app
    return (
        <GroupContext.Provider value={{ groupState, groupDispatch, getGroupFromId }}>
            {props.children}
        </GroupContext.Provider>
    );
};

