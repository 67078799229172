import React, { useState, useContext, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as EmailValidator from "email-validator";
import {
  IAdminPanelUserInvite,
  IAdminPanelUser,
} from "../interface/adminPanelUserInterface";
import { UserRole } from "../interface/userInterface";
import { AdminPanelGroupContext } from "../context/adminPanelGroupContext";
import { AdminPanelUserContext } from "../context/adminPanelUserContext";
import { makeAPICall, APIMethods } from "../utils";
import { addUserToAdminPanel } from "../actions/adminPanelUserActions";
import MuiPhoneNumber from "material-ui-phone-number";

const TWO_SECONDS_IN_MILLIS = 2000;
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButtonGrid = styled(Grid)`
  text-align: right;
`;
const StyledPaper = styled(Paper)`
  width: 550px;
  padding: 20px;
`;

const StyledForm = styled.form`
  margin-top: 10px;
`;
const StyledFormControl = styled(FormControl)`
  &&& {
    margin-top: 15px;
  }
`;

const HalfFormControl = styled(FormControl)`
  &&& {
    display: flex;
    margin-top: 15px;
    flex-direction: row;

    .MuiFormControl-root:nth-child(2) {
      margin-left: 2%;
    }
  }
`;

const NameTextField = styled(TextField)`
  width: 49%;
`;
const StyledSelect = styled(Select)`
  width: 49%;
  margin-left: 2%;
`;

type adminGroupOptions = {
  name: string;
  id: number;
};

export default function UserCreateModal() {
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [userRole, setUserRole] = useState(UserRole.USER);
  const [isDispatcher, setIsDispatcher] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const { adminPanelGroupState } = useContext(AdminPanelGroupContext);
  const { adminPanelUserDispatch } = useContext(AdminPanelUserContext);
  const [groupOptions, setGroupOptions] = useState([] as adminGroupOptions[]);
  const [userGroups, setUserGroups] = useState([] as adminGroupOptions[]);
  const [dispatcherGroups, setDispatcherGroups] = useState(
    [] as adminGroupOptions[]
  );
  const [createError, setCreateError] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  useEffect(() => {
    const adminGroups = adminPanelGroupState.map((adminGroup) => {
      return { id: adminGroup.id, name: adminGroup.name };
    });
    setGroupOptions(adminGroups);
  }, [adminPanelGroupState]);
  const clearModal = () => {
    setDispatcherGroups([]);
    setUserGroups([]);
    setIsDispatcher(false);
    setUserRole(UserRole.USER);
    setPhone("");
    setLastName("");
    setFirstName("");
    setEmail("");
    setIsCreating(false);
    setCreateError("");
  };
  /** Check to see if email is a valid email string */
  const isValidEmail = (email: string) => {
    return EmailValidator.validate(email);
  };
  /**
   * Check to see if this is a valid name
   * @param
   */
  const isValidName = (name: string) => {
    const nameRegex = new RegExp(/^[a-z ,.'-]+$/i);
    return nameRegex.test(name);
  };

  /**
   * Validate email of user
   * @param
   */
  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      setEmailError("");
    } else {
      setEmailError("Enter a valid email");
    }
    setEmail(event.target.value);
  };

  /**
   * Validate first name of user
   */

  const validateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name.length < 2 || !isValidName(name)) {
      setFirstNameError("Must be a valid name least 2 characters long");
    } else {
      setFirstNameError("");
    }
    setFirstName(name);
  };

  /**
   * Validate last name of user
   * @param
   */

  const validateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name.length < 1 || !isValidName(name)) {
      setLastNameError("Must be a valid name least 1 characters long");
    } else {
      setLastNameError("");
    }
    setLastName(name);
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "user") {
      setUserRole(UserRole.USER);
    } else {
      // user is admin. So they MUST be a dispatcher
      setUserRole(UserRole.ADMIN);
      setIsDispatcher(true);
    }
  };

  const handleOpen = () => {
    setShowSnackBar(false);
    setOpen(true);
  };

  const handleClose = () => {
    clearModal();
    setOpen(false);
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const selectUserGroup = (adminGroup: adminGroupOptions[]) => {
    setUserGroups(adminGroup);
  };

  const selectDispatcherGroup = (adminGroup: adminGroupOptions[]) => {
    setDispatcherGroups(adminGroup);
  };
  const createUser = async (event: any) => {
    event.preventDefault();
    setIsCreating(true);
    setCreateError("");
    // first lets validate that all required fields has a value
    if (emailError || firstNameError || lastNameError) {
      setIsCreating(false);
      return false;
    }

    const userGroupIds = userGroups.map((group) => group.id);
    const dispatcherGroupIds = dispatcherGroups.map((group) => group.id);
    const userToCreate = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      email: email,
      is_dispatcher: isDispatcher,
      is_admin: userRole === UserRole.ADMIN,
      dispatcher_groups: dispatcherGroupIds,
      user_groups: userGroupIds,
    } as IAdminPanelUserInvite;
    const createdUser = await makeAPICall(
      "/admin/invites",
      APIMethods.POST,
      userToCreate
    )
      .then((res) => res)
      .catch((err) => err);
    if (createdUser.isAxiosError) {
      setCreateError(createdUser.response.data.detail);
      setIsCreating(false);
      return false;
    }
    // add new user to user admin context
    adminPanelUserDispatch(
      addUserToAdminPanel({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email: email,
        id: createdUser.data.user_id,
        role: userRole,
        is_signed_up: false,
        dispatcher_id: createdUser.data.dispatcher_id,
        date_created: createdUser.data.date_created,
      } as IAdminPanelUser)
    );
    setOpen(false);
    setShowSnackBar(true);
    clearModal();
  };
  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={TWO_SECONDS_IN_MILLIS}
        onClose={handleClose}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          Invite Sent Successfully
        </Alert>
      </Snackbar>
      <StyledButtonGrid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Invite User
        </Button>
      </StyledButtonGrid>
      <StyledModal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <StyledPaper elevation={1}>
            <Typography variant="h6">Add User</Typography>
            <StyledForm onSubmit={createUser}>
              <div>
                <StyledFormControl fullWidth>
                  <TextField
                    error={!!emailError}
                    helperText={emailError}
                    required
                    value={email}
                    onChange={validateEmail}
                    variant="outlined"
                    label="Email"
                  />
                </StyledFormControl>
                <HalfFormControl fullWidth>
                  <NameTextField
                    required
                    error={!!firstNameError}
                    helperText={firstNameError}
                    label="First Name"
                    onChange={validateFirstName}
                    value={firstName}
                    variant="outlined"
                  />
                  <NameTextField
                    required
                    error={!!lastNameError}
                    helperText={lastNameError}
                    label="Last Name"
                    onChange={validateLastName}
                    value={lastName}
                    variant="outlined"
                  />
                </HalfFormControl>
                <HalfFormControl fullWidth>
                  <MuiPhoneNumber
                    label="Phone Number"
                    onChange={(e) => setPhone(e)}
                    value={phone}
                    variant="outlined"
                    defaultCountry={"ca"}
                  />
                  <StyledSelect
                    value={userRole}
                    onChange={handleRoleChange}
                    variant="outlined"
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </StyledSelect>
                </HalfFormControl>

                <StyledFormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isDispatcher}
                        onChange={() => setIsDispatcher(!isDispatcher)}
                      />
                    }
                    labelPlacement="start"
                    label="Make User Dispatcher"
                    disabled={userRole === UserRole.ADMIN}
                  />
                  {isDispatcher && (
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={groupOptions}
                      getOptionLabel={(group) => group.name}
                      onChange={(event, newValue) => {
                        selectDispatcherGroup(newValue);
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Assign The Dispatcher To A Group"
                          placeholder="Assign The Dispatcher To A Group"
                        />
                      )}
                    />
                  )}
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={groupOptions}
                    getOptionLabel={(group) => group.name}
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      selectUserGroup(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Assign The User To A Group"
                        placeholder="Assign The User To A Group"
                      />
                    )}
                  />
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <StyledButtonGrid item xs={12}>
                    {createError && (
                      <Alert severity="error">{createError}</Alert>
                    )}
                    {isCreating ? (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled
                      >
                        Creating...
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary" type="submit">
                        Invite User
                      </Button>
                    )}
                  </StyledButtonGrid>
                </StyledFormControl>
              </div>
            </StyledForm>
          </StyledPaper>
        </Fade>
      </StyledModal>
    </>
  );
}
