import React, { useState, useContext, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import EmailIcon from "@material-ui/icons/Email";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  IAdminPanelUser,
  IEditAdminPanelUser,
} from "../interface/adminPanelUserInterface";
import { UserRole } from "../interface/userInterface";
import { AdminPanelGroupContext } from "../context/adminPanelGroupContext";
import { AdminPanelUserContext } from "../context/adminPanelUserContext";
import { makeAPICall, APIMethods } from "../utils";
import ProfilePictureUser from "./ProfilePictureUser";
import Chip from "@material-ui/core/Chip";
import { IGroup } from "../interface/groupInterface";
import { updateAdminPanelUser } from "../actions/adminPanelUserActions";
import MuiPhoneNumber from "material-ui-phone-number";
const TWO_SECONDS_IN_MILLIS = 2000;
const StyledDialog = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  width: 550px;
  padding: 25px;
`;

const CenteredGrid = styled(Grid)`
  &&& {
    text-align: center;
  }
`;
const NameTextField = styled(TextField)``;
const StyledSelect = styled(Select)`
  width: 49%;
  margin-left: 2%;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`;

type adminGroupOptions = {
  name: string;
  id: number;
};

type userToEditProps = {
  userToEdit: IAdminPanelUser;
  closeEditUserPanel: any;
  isEditUserPanelOpen: boolean;
};
type dispatcherAPIReturn = {
  id: number;
  user_id: number;
};

export default function EditUserModal({
  userToEdit,
  closeEditUserPanel,
  isEditUserPanelOpen,
}: userToEditProps) {
  const [email, setEmail] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [editFirstNameValue, setEditFirstNameValue] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [editLastNameValue, setEditLastNameValue] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [editPhoneValue, setEditPhoneValue] = useState<string>("");
  const [isPhoneEditing, setIsPhoneEditing] = useState<boolean>(false);
  const [userRole, setUserRole] = useState(UserRole.USER);
  const [editUserRoleValue, setEditUserRoleValue] = useState<UserRole>(
    UserRole.USER
  );
  const [isRoleEditing, setIsRoleEditing] = useState<boolean>(false);
  const [isDispatcher, setIsDispatcher] = useState<boolean>(false);
  const [editIsDispatcherValue, setEditIsDispatcherValue] = useState<boolean>(
    false
  );
  const [isDispatcherEditing, setIsDispatcherEditing] = useState<boolean>(
    false
  );
  const [showSuccessSnackBar, setShowSuccessSnackBar] = useState<boolean>(
    false
  );
  const [showErrorSnackBar, setShowErrorSnackBar] = useState<boolean>(false);
  const { adminPanelGroupState, getGroupById } = useContext(
    AdminPanelGroupContext
  );
  const { adminPanelUserDispatch } = useContext(AdminPanelUserContext);
  const [groupOptions, setGroupOptions] = useState([] as adminGroupOptions[]);
  const [userGroups, setUserGroups] = useState([] as adminGroupOptions[]);
  const [editUserGroups, setEditUserGroups] = useState(
    [] as adminGroupOptions[]
  );
  const [dispatcherGroups, setDispatcherGroups] = useState(
    [] as adminGroupOptions[]
  );
  const [editDispatcherGroups, setEditDispatcherGroups] = useState(
    [] as adminGroupOptions[]
  );
  const [editError, setEditError] = useState<string>("");
  const [dispatcherId, setDispatcherId] = useState<number | null>(null);
  const [dateCreated, setDateCreated] = useState<string>("");
  const [isDispatcherGroupsEditing, setIsDispatcherGroupsEditing] = useState<
    boolean
  >(false);
  const [isUserGroupsEditing, setIsUserGroupsEditing] = useState<boolean>(
    false
  );
  const [isUserGroupsLoading, setIsUserGroupsLoading] = useState<boolean>(true);
  const [isDispatcherGroupsLoading, setIsDispatcherGroupsLoading] = useState<
    boolean
  >(true);
  useEffect(() => {
    // provide fresh modal
    clearModal();
    const adminGroups = adminPanelGroupState.map((adminGroup) => {
      return { id: adminGroup.id, name: adminGroup.name };
    });
    setGroupOptions(adminGroups);
    console.log(userToEdit);
    // Make sure there is a userToEdit selected
    if (Object.keys(userToEdit).length > 0) {
      setFirstName(userToEdit.first_name);
      setEditFirstNameValue(userToEdit.first_name);
      setLastName(userToEdit.last_name);
      setEditLastNameValue(userToEdit.last_name);
      setEmail(userToEdit.email);
      setUserRole(userToEdit.role);
      setEditUserRoleValue(userToEdit.role);
      setId(userToEdit.id);
      setPhone(userToEdit.phone_number ? userToEdit.phone_number : "");
      setEditPhoneValue(userToEdit.phone_number ? userToEdit.phone_number : "");
      setIsSignedUp(userToEdit.is_signed_up);
      setDateCreated(userToEdit.date_created);
      // gather user_groups
      makeAPICall(`/users/${userToEdit.id}/groups`, APIMethods.GET, null).then(
        (res) => {
          const userGroups = res.data.map((res: IGroup) => {
            return { id: res.id, name: res.name } as adminGroupOptions;
          });
          setUserGroups(userGroups);
          setEditUserGroups(userGroups);
          setIsUserGroupsLoading(false);
        }
      );
      // gather dispatcher groups if a user is a dispatcher
      if (userToEdit.dispatcher_id) {
        setIsDispatcher(true);
        setDispatcherId(userToEdit.dispatcher_id);
        setEditIsDispatcherValue(true);
        makeAPICall(
          `/dispatchers/${userToEdit.dispatcher_id}/groups`,
          APIMethods.GET,
          null
        ).then((res) => {
          const dispatcherGroups = res.data.map((res: IGroup) => {
            return { id: res.id, name: res.name } as adminGroupOptions;
          });
          setDispatcherGroups(dispatcherGroups);
          setEditDispatcherGroups(dispatcherGroups);
          setIsDispatcherGroupsLoading(false);
        });
      }
    }
  }, [adminPanelGroupState, userToEdit, getGroupById]);
  const clearModal = () => {
    setIsNameEditing(false);
    setIsPhoneEditing(false);
    setIsRoleEditing(false);
    setIsDispatcherEditing(false);
    setIsUserGroupsEditing(false);
    setIsDispatcherGroupsEditing(false);
    setIsDispatcherGroupsLoading(true);
    setIsUserGroupsLoading(true);
    setIsDispatcher(false);
  };
  /**
   * Check to see if this is a valid name
   * @param
   */
  const isValidName = (name: string) => {
    const nameRegex = new RegExp(/^[a-z ,.'-]+$/i);
    return nameRegex.test(name);
  };

  /**
   * Validate first name of user
   */

  const validateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name.length < 2 || !isValidName(name)) {
      setFirstNameError("Must be a valid name least 2 characters long");
    } else {
      setFirstNameError("");
    }
    setEditFirstNameValue(name);
  };

  /**
   * Validate last name of user
   * @param
   */

  const validateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name.length < 1 || !isValidName(name)) {
      setLastNameError("Must be a valid name least 1 characters long");
    } else {
      setLastNameError("");
    }
    setEditLastNameValue(name);
  };

  // This function is used to manage when a user cancels editing the first and last name values
  const cancelNameEditing = () => {
    setEditFirstNameValue(firstName);
    setEditLastNameValue(lastName);
    setIsNameEditing(false);
  };

  // This function manages the action when user cancels phone edit action
  const cancelPhoneEditing = () => {
    setEditPhoneValue(phone);
    setIsPhoneEditing(false);
  };

  // This function manages the action when user cancels role edit action
  const cancelRoleEditing = () => {
    setEditUserRoleValue(userRole);
    setIsRoleEditing(false);
  };

  // This function manages the action when user cancels is dispatcher edit action
  const cancelIsDispatcherEditing = () => {
    setEditIsDispatcherValue(isDispatcher);
    setIsDispatcherEditing(false);
  };

  // This function manages the action when user cancels dispatcher groups edit action
  const cancelIsDispatcherGroupsEditing = () => {
    setEditDispatcherGroups(dispatcherGroups);
    setIsDispatcherGroupsEditing(false);
  };

  // This function manages the action when user cancels user groups edit action
  const cancelIsUserGroupsEditing = () => {
    setEditUserGroups(userGroups);
    setIsUserGroupsEditing(false);
  };
  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "user") {
      setEditUserRoleValue(UserRole.USER);
    } else {
      // user is admin. So they MUST be a dispatcher
      setEditUserRoleValue(UserRole.ADMIN);
    }
  };

  const handleCloseSuccessSnackBar = () => {
    setShowSuccessSnackBar(false);
  };
  const handleCloseErrorSnackBar = () => {
    setShowErrorSnackBar(false);
  };
  const selectUserGroup = (adminGroup: adminGroupOptions[]) => {
    setEditUserGroups(adminGroup);
  };

  const selectDispatcherGroup = (adminGroup: adminGroupOptions[]) => {
    setEditDispatcherGroups(adminGroup);
  };

  // This function is used to edit the user name
  const editUserName = async () => {
    // dont allow create on validation error
    if (firstNameError || lastNameError !== "") {
      return false;
    }
    // dont allow create if name values are the same
    if (editFirstNameValue === firstName && editLastNameValue === lastName) {
      setEditError("You must use a different name");
      setShowErrorSnackBar(true);
      return false;
    }
    const fieldsToEdit = {
      first_name: editFirstNameValue,
      last_name: editLastNameValue,
    };
    const editedUser = await makeAPICall(
      `/users/${id}`,
      APIMethods.PUT,
      fieldsToEdit
    )
      .then((res) => res)
      .catch((err) => err);
    if (editedUser.isAxiosError) {
      setEditError(editedUser.response.data.detail);
      setShowErrorSnackBar(true);
      return false;
    }

    setFirstName(editFirstNameValue);
    setLastName(editLastNameValue);
    setIsNameEditing(false);
    const updateUser = {
      id: id,
      first_name: editFirstNameValue,
      last_name: editLastNameValue,
    } as IEditAdminPanelUser;
    // dispatch state change
    adminPanelUserDispatch(updateAdminPanelUser(updateUser));
    setShowSuccessSnackBar(true);
  };

  // This function is used to edit the users phone number
  const editPhone = async () => {
    // dont allow create if values are the same
    if (editPhoneValue === phone) {
      setEditError("You must use a different number");
      setShowErrorSnackBar(true);
      return false;
    }
    const fieldsToEdit = { phone_number: editPhoneValue };
    const editedUser = await makeAPICall(
      `/users/${id}`,
      APIMethods.PUT,
      fieldsToEdit
    )
      .then((res) => res)
      .catch((err) => err);
    if (editedUser.isAxiosError) {
      setEditError(editedUser.response.data.detail);
      setShowErrorSnackBar(true);
      return false;
    }
    setPhone(editPhoneValue);
    setIsPhoneEditing(false);
    const updateUser = {
      id: id,
      phone_number: editPhoneValue,
    } as IEditAdminPanelUser;
    // dispatch state change
    adminPanelUserDispatch(updateAdminPanelUser(updateUser));
    setShowSuccessSnackBar(true);
  };
  // This function is used to create new dispatcher
  const createDispatcher = async (): Promise<dispatcherAPIReturn> => {
    const dispatcher = await makeAPICall(
      `/admin/dispatchers`,
      APIMethods.POST,
      {
        user_id: id,
      }
    )
      .then((res) => res)
      .catch((err) => err);
    if (dispatcher.isAxiosError) {
      setEditError(dispatcher.response.data.detail);
      setShowErrorSnackBar(true);
      throw new Error("Create dispatcher failed");
    }
    setIsDispatcher(true);
    setDispatcherId(dispatcher.data.id);
    return dispatcher.data;
  };
  // This function is used to edit the users role
  const editRole = async () => {
    // dont allow create if values are the same
    if (editUserRoleValue === userRole) {
      setEditError("You must select a differet role");
      setShowErrorSnackBar(true);
      return false;
    }
    const fieldsToEdit = { role: editUserRoleValue };
    const editedUser = await makeAPICall(
      `/users/${id}`,
      APIMethods.PUT,
      fieldsToEdit
    )
      .then((res) => res)
      .catch((err) => err);
    if (editedUser.isAxiosError) {
      setEditError(editedUser.response.data.detail);
      setShowErrorSnackBar(true);
      return false;
    }
    // generate update fields
    const updateUser = {
      id: id,
      role: editUserRoleValue,
    } as IEditAdminPanelUser;
    setUserRole(editUserRoleValue);

    // if user was made into an admin, we will also need to create them as a dispatcher
    //if they are not already a dispatcher
    // and update dispatcher id field
    if (editUserRoleValue === UserRole.ADMIN && !dispatcherId) {
      const dispatcher = await createDispatcher();
      updateUser["dispatcher_id"] = dispatcher.id;
      setIsDispatcherGroupsLoading(false);
    }

    setIsRoleEditing(false);
    // dispatch state change
    adminPanelUserDispatch(updateAdminPanelUser(updateUser));
    setShowSuccessSnackBar(true);
  };
  // This function is used to delete the dispatcher
  const deleteDispatcher = async () => {
    const dispatcher = await makeAPICall(
      `/admin/dispatchers/${dispatcherId}`,
      APIMethods.DELETE,
      null
    )
      .then((res) => res)
      .catch((err) => err);
    if (dispatcher.isAxiosError) {
      setEditError(dispatcher.response.data.detail);
      setShowErrorSnackBar(true);
      throw new Error("delete dispatcher failed");
    }
    setDispatcherId(null);
    setIsDispatcher(false);
  };
  const handleDialogClose = () => {
    clearModal();
    closeEditUserPanel();
  };
  //  THis function is used to edit whether a user is a dispatcher or not
  const editDispatcher = async () => {
    // dont allow create if values are the same
    if (editIsDispatcherValue === isDispatcher) {
      setEditError("You must change the value of the dispatcher");
      setShowErrorSnackBar(true);
      return false;
    }
    const editFields = { id: id } as IEditAdminPanelUser;
    // If the edit value is now false, it means that we must delete the dispatcher
    if (editIsDispatcherValue === false) {
      deleteDispatcher();
      editFields["dispatcher_id"] = null;
    } else {
      const dispatcher = await createDispatcher();
      editFields["dispatcher_id"] = dispatcher.id;
    }
    adminPanelUserDispatch(updateAdminPanelUser(editFields));
    setIsDispatcherEditing(false);
    setShowSuccessSnackBar(true);
  };
  const findGroupsToDelete = (
    initialGroups: adminGroupOptions[],
    newGroupValues: adminGroupOptions[]
  ): adminGroupOptions[] => {
    const groupsToDelete = [] as adminGroupOptions[];
    for (const group of initialGroups) {
      // there is no matching group in the new values. So that means we need to delete
      if (!newGroupValues.find((newGroup) => newGroup.id === group.id)) {
        groupsToDelete.push(group);
      }
    }
    return groupsToDelete;
  };

  const findGroupsToAdd = (
    initialGroups: adminGroupOptions[],
    newGroupValues: adminGroupOptions[]
  ): adminGroupOptions[] => {
    const groupsToAdd = [] as adminGroupOptions[];
    for (const group of newGroupValues) {
      // there is no matching group in the old values, that means we need to add
      if (!initialGroups.find((oldGroup) => oldGroup.id === group.id)) {
        groupsToAdd.push(group);
      }
    }
    return groupsToAdd;
  };
  //  add/remove user groups
  const changeUserGroups = async () => {
    // first thing we need to do is find the differences
    const groupsToDelete = findGroupsToDelete(userGroups, editUserGroups);
    const groupsToAdd = findGroupsToAdd(userGroups, editUserGroups);
    // No changes to user groups
    if (groupsToAdd.length === 0 && groupsToDelete.length === 0) {
      setEditError("You must add or remove a user groups");
      setShowErrorSnackBar(true);
      return false;
    }
    const requestsToMake = [] as Promise<any>[];
    // prepare group to delete requests
    for (const groupToDelete of groupsToDelete) {
      requestsToMake.push(
        makeAPICall(
          `/admin/user_groups?user_id=${id}&group_id=${groupToDelete.id}`,
          APIMethods.DELETE,
          null
        )
      );
    }
    // prepare group to add requests
    for (const groupToAdd of groupsToAdd) {
      requestsToMake.push(
        makeAPICall(`/admin/user_groups`, APIMethods.POST, {
          group_id: groupToAdd.id,
          user_id: id,
        })
      );
    }
    // make api calls to delete/create user groups
    const returnUserGroupActions = await Promise.all(requestsToMake)
      .then((res) => res)
      .catch((err) => err);
    // check for errors
    for (const returnUserGroupAction of returnUserGroupActions) {
      if (returnUserGroupAction.isAxiosError) {
        setEditError(returnUserGroupAction.response.data.detail);
        setShowErrorSnackBar(true);
        throw new Error("Create user group failed");
      }
      setUserGroups(editUserGroups);
      setIsUserGroupsEditing(false);
      setShowSuccessSnackBar(true);
    }
  };

  //  add/remove user groups
  const changeDispatcherGroups = async () => {
    // first thing we need to do is find the differences
    const groupsToDelete = findGroupsToDelete(
      dispatcherGroups,
      editDispatcherGroups
    );
    const groupsToAdd = findGroupsToAdd(dispatcherGroups, editDispatcherGroups);
    // No changes to user groups
    if (groupsToAdd.length === 0 && groupsToDelete.length === 0) {
      setEditError("You must add or remove a dispatcher group");
      setShowErrorSnackBar(true);
      return false;
    }
    const requestsToMake = [] as Promise<any>[];
    // prepare group to delete requests
    for (const groupToDelete of groupsToDelete) {
      requestsToMake.push(
        makeAPICall(
          `/admin/dispatcher_groups?dispatcher_id=${dispatcherId}&group_id=${groupToDelete.id}`,
          APIMethods.DELETE,
          null
        )
      );
    }
    // prepare group to add requests
    for (const groupToAdd of groupsToAdd) {
      requestsToMake.push(
        makeAPICall(`/admin/dispatcher_groups`, APIMethods.POST, {
          group_id: groupToAdd.id,
          dispatcher_id: dispatcherId,
        })
      );
    }
    // make api calls to delete/create user groups
    const returnDispatcherGroupActions = await Promise.all(requestsToMake)
      .then((res) => res)
      .catch((err) => err);
    // check for errors
    for (const returnDispatcherGroupAction of returnDispatcherGroupActions) {
      if (returnDispatcherGroupAction.isAxiosError) {
        setEditError(returnDispatcherGroupAction.response.data.detail);
        setShowErrorSnackBar(true);
        throw new Error("Create dispatcher group failed");
      }
      setDispatcherGroups(editDispatcherGroups);
      setIsDispatcherGroupsEditing(false);
      setShowSuccessSnackBar(true);
    }
  };

  return (
    <>
      <Snackbar
        open={showSuccessSnackBar}
        autoHideDuration={TWO_SECONDS_IN_MILLIS}
        onClose={handleCloseSuccessSnackBar}
      >
        <Alert onClose={handleCloseSuccessSnackBar} severity="success">
          User successfully updated
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackBar}
        autoHideDuration={TWO_SECONDS_IN_MILLIS}
        onClose={handleCloseErrorSnackBar}
      >
        <Alert onClose={handleCloseErrorSnackBar} severity="error">
          {editError}
        </Alert>
      </Snackbar>
      <StyledDialog
        open={isEditUserPanelOpen}
        onClose={handleDialogClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        scroll="body"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isEditUserPanelOpen}>
          <StyledPaper elevation={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProfilePictureUser
                  profile_picture_url={userToEdit.profile_picture_url}
                />
              </Grid>
              <CenteredGrid item container xs={12} spacing={1}>
                {isNameEditing ? (
                  <>
                    <Grid item xs={5}>
                      <NameTextField
                        required
                        size="small"
                        error={!!firstNameError}
                        helperText={firstNameError}
                        label="First Name"
                        onChange={validateFirstName}
                        value={editFirstNameValue}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <NameTextField
                        required
                        size="small"
                        error={!!lastNameError}
                        helperText={lastNameError}
                        label="Last Name"
                        onChange={validateLastName}
                        value={editLastNameValue}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton size="small">
                        <CheckCircleIcon
                          fontSize="small"
                          color="primary"
                          onClick={editUserName}
                        />
                      </IconButton>
                      <IconButton size="small">
                        <CancelIcon
                          fontSize="small"
                          color="error"
                          onClick={cancelNameEditing}
                        />
                      </IconButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {firstName} {lastName}{" "}
                      <IconButton size="small">
                        <EditIcon
                          fontSize="small"
                          onClick={() => setIsNameEditing(true)}
                        />
                      </IconButton>
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {isSignedUp ? (
                    <Chip label="Active" color="primary" icon={<DoneIcon />} />
                  ) : (
                    <Chip label="Invited" icon={<EmailIcon />} />
                  )}
                </Grid>
              </CenteredGrid>
              <Grid item xs={6}>
                {isPhoneEditing ? (
                  <>
                    <MuiPhoneNumber
                      label="Phone Number"
                      onChange={(e) => setEditPhoneValue(e)}
                      value={editPhoneValue}
                      defaultCountry={"ca"}
                    />
                    <IconButton size="small">
                      <CheckCircleIcon
                        fontSize="small"
                        color="primary"
                        onClick={editPhone}
                      />
                    </IconButton>
                    <IconButton size="small">
                      <CancelIcon
                        fontSize="small"
                        color="error"
                        onClick={cancelPhoneEditing}
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">Phone</Typography>
                    <Typography variant="subtitle1">
                      {phone}{" "}
                      <IconButton size="small">
                        <EditIcon
                          fontSize="small"
                          onClick={() => setIsPhoneEditing(true)}
                        />
                      </IconButton>
                    </Typography>
                  </>
                )}
              </Grid>

              <Grid item xs={6}>
                {isRoleEditing ? (
                  <>
                    <StyledSelect
                      value={editUserRoleValue}
                      onChange={handleRoleChange}
                      variant="outlined"
                    >
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                    </StyledSelect>
                    <IconButton size="small">
                      <CheckCircleIcon
                        fontSize="small"
                        color="primary"
                        onClick={editRole}
                      />
                    </IconButton>
                    <IconButton size="small">
                      <CancelIcon
                        fontSize="small"
                        color="error"
                        onClick={cancelRoleEditing}
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">Role</Typography>
                    <Typography variant="subtitle1">
                      {userRole}{" "}
                      <IconButton size="small">
                        <EditIcon
                          fontSize="small"
                          onClick={() => setIsRoleEditing(true)}
                        />
                      </IconButton>
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Date Created</Typography>
                <Typography variant="subtitle1">{dateCreated}</Typography>
              </Grid>
              <Grid item xs={6}>
                {isDispatcherEditing ? (
                  <>
                    <Typography variant="subtitle2">
                      Is User Dispatcher
                    </Typography>
                    <Switch
                      checked={editIsDispatcherValue}
                      onChange={() =>
                        setEditIsDispatcherValue(!editIsDispatcherValue)
                      }
                    />
                    <IconButton size="small">
                      <CheckCircleIcon
                        fontSize="small"
                        color="primary"
                        onClick={editDispatcher}
                      />
                    </IconButton>
                    <IconButton size="small">
                      <CancelIcon
                        fontSize="small"
                        color="error"
                        onClick={cancelIsDispatcherEditing}
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">
                      Is User Dispatcher
                    </Typography>
                    <Switch disabled checked={isDispatcher} />
                    {userRole === UserRole.USER && (
                      <IconButton size="small">
                        <EditIcon
                          fontSize="small"
                          onClick={() => setIsDispatcherEditing(true)}
                        />
                      </IconButton>
                    )}
                  </>
                )}
              </Grid>
              {isDispatcher && (
                <Grid item xs={12}>
                  {isDispatcherGroupsEditing ? (
                    <>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={groupOptions}
                        getOptionLabel={(group) => group.name}
                        onChange={(event, newValue) => {
                          selectDispatcherGroup(newValue);
                        }}
                        defaultValue={editDispatcherGroups}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Assign The Dispatcher To A Group"
                            placeholder="Assign The Dispatcher To A Group"
                          />
                        )}
                      />

                      <StyledButtonContainer>
                        <IconButton size="small">
                          <CheckCircleIcon
                            fontSize="small"
                            color="primary"
                            onClick={changeDispatcherGroups}
                          />
                        </IconButton>
                        <IconButton size="small">
                          <CancelIcon
                            fontSize="small"
                            color="error"
                            onClick={cancelIsDispatcherGroupsEditing}
                          />
                        </IconButton>
                      </StyledButtonContainer>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2" gutterBottom>
                        Dispatcher Groups
                        {/* only allow editing when groups are done loading */}
                        {!isDispatcherGroupsLoading && (
                          <IconButton size="small">
                            <EditIcon
                              fontSize="small"
                              onClick={() => setIsDispatcherGroupsEditing(true)}
                            />
                          </IconButton>
                        )}
                      </Typography>
                      <Grid xs={12}>
                        {dispatcherGroups.map((group) => {
                          return (
                            <Chip
                              key={group.id}
                              label={group.name}
                              size="small"
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                {isUserGroupsEditing ? (
                  <>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={groupOptions}
                      getOptionLabel={(group) => group.name}
                      onChange={(event, newValue) => {
                        selectUserGroup(newValue);
                      }}
                      defaultValue={editUserGroups}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Assign The User To A Group"
                          placeholder="Assign The User To A Group"
                        />
                      )}
                    />
                    <StyledButtonContainer>
                      <IconButton size="small">
                        <CheckCircleIcon
                          fontSize="small"
                          color="primary"
                          onClick={changeUserGroups}
                        />
                      </IconButton>
                      <IconButton size="small">
                        <CancelIcon
                          fontSize="small"
                          color="error"
                          onClick={cancelIsUserGroupsEditing}
                        />
                      </IconButton>
                    </StyledButtonContainer>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      User Groups
                      {/* only allow editing when groups are done loading */}
                      {!isUserGroupsLoading && (
                        <IconButton size="small">
                          <EditIcon
                            fontSize="small"
                            onClick={() => setIsUserGroupsEditing(true)}
                          />
                        </IconButton>
                      )}
                    </Typography>
                    <Grid xs={12}>
                      {userGroups.map((group) => {
                        return (
                          <Chip
                            key={group.id}
                            label={group.name}
                            size="small"
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </StyledPaper>
        </Fade>
      </StyledDialog>
    </>
  );
}
