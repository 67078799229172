import {
  IWorker,
  IWorkerAction,
  IWorkerApiCall,
} from "../interface/workerInterface";
import gql from "graphql-tag";
import { API } from "aws-amplify";
import Observable from "zen-observable";
import { makeAPICall, APIMethods } from "../utils";
import { ICheckin } from "../interface/checkinInterface";
import { IUser } from "../interface/userInterface";

export enum WORKER_ACTION_TYPES {
  GET_MONITORED_WORKERS_FROM_GROUP_ID = "GET_MONITORED_WORKERS_FROM_GROUP_ID",
  UPDATE_MONITORED_USER = "UPDATE_MONITORED_USER",
  CREATE_MONITORED_USER = "CREATE_MONITORED_USER",
  DELETE_MONITORED_USER = "DELETE_MONITORED_USER",
  UPDATE_MONITORED_USER_CHECKIN = "UPDATE_MONITORED_USER_CHECKIN",
}

/**This function performs an api query to get the list of user data from api and return IWorker data with API fields
 * We require this function because appsync doesnt return complete data(i.e name,company etc)
 * @params groupIds number[]: list of all the group IDs to  retrieve monitored workers from
 * @returns list of user data
 */
export const getAPIWorkerDataFromCheckin = async (
  checkins: ICheckin[]
): Promise<IWorker[]> => {
  // gather unique group ids to query
  const idsToQuery = [
    ...new Set(
      checkins.map((res) => {
        return res.group_id;
      })
    ),
  ];

  // call api
  const groupsToQuery = [] as Promise<any>[];
  for (const groupId of idsToQuery) {
    groupsToQuery.push(
      makeAPICall(`/groups/${groupId}/users`, APIMethods.GET, null)
    );
  }
  const apiDataResponse = await Promise.all(groupsToQuery)
    .then((res) => res)
    .catch((err) => err);
  let apiData: IUser[] = apiDataResponse
    .map((responseData: any) => responseData.data)
    .flat();

  const workerDataToReturn: IWorker[] = [];
  for (const checkinData of checkins) {
    // get the user data by matching with checkin id
    const apiDataWithId = apiData.find(
      (item: IWorkerApiCall) => item.id === checkinData.user_id
    );
    // if Array.find could not find a value returned with matching id, goto next and dont return,
    if (apiDataWithId === undefined) {
      continue;
    }
    // generate new worker
    const workerData = {} as IWorker;
    workerData.company = apiDataWithId.company;
    workerData.organization_id = apiDataWithId.organization_id;
    workerData.first_name = apiDataWithId.first_name;
    workerData.last_name = apiDataWithId.last_name;
    workerData.phone_number = apiDataWithId.phone_number;
    workerData.last_checkin = checkinData;
    workerData.user_id = checkinData.user_id;
    workerData.group_id = checkinData.group_id;
    workerData.profile_picture_url = apiDataWithId.profile_picture_url;
    // add new appended worker data to return
    workerDataToReturn.push(workerData);
  }
  return workerDataToReturn;
};
/**This function performs an appsync query to get all monitored workers
 * @params groupIds number[]: list of all the group IDs to  retrieve monitored workers from
 * @returns list of all monitored workers from appsync client
 */
export const getMontitoredWorkersFromGroupId = async (
  groupIds: number[]
): Promise<IWorkerAction> => {
  const workerAppsyncQueries = [];
  for (const groupId of groupIds) {
    const listMonitoredWorkers = gql`
        query MyQuery {
        getCheckinStatusesForGroup(group_id:${groupId}) {
            checkin_type
            date_created
            group_id
            latitude
            longitude
            ttl
            user_id
            }
        }
        `;
    // append get worker by group id  to list of promise to return
    workerAppsyncQueries.push(
      API.graphql({ query: listMonitoredWorkers }) as any
    );
  }
  // prepare list of workers to return
  let appsyncWorkerData: ICheckin[] = [];
  // call graphql api
  const returnedAppSyncData = await Promise.all(workerAppsyncQueries).then(
    (res) => {
      return res as any;
    }
  );
  //add return workers together from appsync
  for (const workerAppsyncData of returnedAppSyncData) {
    appsyncWorkerData = [
      ...appsyncWorkerData,
      ...workerAppsyncData.data.getCheckinStatusesForGroup,
    ];
  }
  // gather data of user from api as appsync does not return all user data
  const apiWorkerData = await getAPIWorkerDataFromCheckin(
    appsyncWorkerData
  ).then((res) => res);

  return {
    type: WORKER_ACTION_TYPES.GET_MONITORED_WORKERS_FROM_GROUP_ID,
    payload: apiWorkerData,
  };
};

/**This function subscribes to all changes that occur in a group
 * @params groupId number: groupId to subscribe to update event
 * @returns
 */
export const subscribeToUpdateGroupId = (groupId: number): Observable<any> => {
  // first get all the groups that this dispatcher is assigned to
  const subscribeGroupId: any = gql`
        subscription OnUpdateCheckinStatusForGroup {
            onUpdateCheckinStatus(group_id: ${groupId}) {
              checkin_type
              date_created
              group_id
              latitude
              ttl
              longitude
              user_id
            }
          }
        `;
  // generate subscription
  return (API.graphql({ query: subscribeGroupId }) as unknown) as Observable<
    any
  >;
};

/**This function subscribes to all new create events in the group
 * @params groupId number: groupId to subscribe to create event
 * @returns
 */
export const subscribeToCreateGroupId = (groupId: number): Observable<any> => {
  // first get all the groups that this dispatcher is assigned to
  const subscribeGroupId: any = gql`
        subscription OnCreateCheckinStatusForGroup {
            onCreateCheckinStatus(group_id: ${groupId}) {
              checkin_type
              date_created
              group_id
              latitude
              ttl
              longitude
              user_id
            }
          }
        `;
  // generate subscription
  return (API.graphql({ query: subscribeGroupId }) as unknown) as Observable<
    any
  >;
};

/**This function subscribes to all new create events in the group
 * @params groupId number: the group ID to subscribe to delete events
 * @returns
 */
export const subscribeToDeleteGroupId = (groupId: number): Observable<any> => {
  // first get all the groups that this dispatcher is assigned to
  const subscribeGroupId: any = gql`
        subscription OnDeleteCheckinStatusForGroup {
            onDeleteCheckinStatus(group_id: ${groupId}) {
              group_id
              user_id
            }
          }
        `;
  // generate subscription
  return (API.graphql({ query: subscribeGroupId }) as unknown) as Observable<
    any
  >;
};
