import { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import styled from 'styled-components'
import { CheckinType } from '../interface/checkinInterface';

type CheckinStatusProps = {
    dateCreated: string
    ttlSeconds: number
    checkinType: CheckinType
}
type CheckinDisplayProps = {
    readonly type: CheckinDisplayType;
}

enum CheckinDisplayType {
    ok,
    emergency,
    missed
}
const StyledPaper = styled(Paper) <CheckinDisplayProps>`
&&&{
    padding:6px;
    background: ${(props: any) => {
        if (props.type === CheckinDisplayType.emergency) {
            return "#f11c78"
        } else if (props.type === CheckinDisplayType.missed) {
            return "#fff4c5"
        } else if (props.type === CheckinDisplayType.ok) {
            return "#c1ffd5"
        }
    }}
}
`

const StyledCheckinHeader = styled(Typography)`
&&&{
text-align:center;
font-weight:800;
font-size:1.2rem
}
`

const StyledCheckinSubHeader = styled(Typography)`
text-align:center;
`
/**
 * 
 * @param dateCreated checkin date created in string
 * @returns
 */
const calculateCheckinSecondsDifference = (dateCreated: string): number => {
    return Math.ceil(((new Date(dateCreated).getTime() - new Date().getTime()) / 1000))
}
/**
 * 
 * Calculate the seconds difference between ttl and last checkin
 * @params ttlSeconds This is the ttl in seconds of the checkin.
 * @params lastCheckinSecondDifference This is how long ago the last checkin was
 * @returns seconds to next checkin(if positive) or how long checkin has been missed(if negative)
 */
const calculateNextCheckinSecondsDifference = (ttlSeconds: number, lastCheckinSecondDifference: number): number => {
    return lastCheckinSecondDifference + ttlSeconds
}
/**
 * 
 * @param nextCheckinSeconds This is the seconds until next checkin. Can be positive,
 * if checkin to occur in future, or negative if user has missed checkin
 * @returns String number of minutes or seconds checkin has been missed
 */
const createCheckinStringValue = (nextCheckinSeconds: number): string => {
    if (Math.abs(nextCheckinSeconds) > 60) {
        return `${Math.ceil(nextCheckinSeconds / 60).toString()} mins`
    }

    return `${nextCheckinSeconds} secs`
}
/** 
 * @param nextCheckinSeconds This is the seconds until next checkin. Can be positive,
 * if checkin to occur in future, or negative if user has missed checkin
 * @param checkinType checkin type can be 
 */
const getCheckinDisplayType = (nextCheckinSeconds: number, checkinType: CheckinType) => {
    if (nextCheckinSeconds > 0) {
        return CheckinDisplayType.ok
    } else if (nextCheckinSeconds < 0 && checkinType === CheckinType.emergency) {
        return CheckinDisplayType.emergency
    } else {
        return CheckinDisplayType.missed
    }
}

export default function CheckinStatusPaper({ dateCreated, ttlSeconds, checkinType }: CheckinStatusProps) {
    const [counter, setCounter] = useState(0)
    const [nextCheckinSeconds, setNextCheckinSeconds] = useState(0)
    useEffect(() => {
        // get when the last checkin was made in minutes
        const checkinSecondsDifference = calculateCheckinSecondsDifference(dateCreated)

        // we need to check if user has passed last checkin, and if so by how many seconds
        const nextCheckinSeconds = calculateNextCheckinSecondsDifference(ttlSeconds, checkinSecondsDifference)
        setNextCheckinSeconds(nextCheckinSeconds)

        // we want to run useEffect at 5 second intervals if there are minutes 
        // left before checkin expires or every second if they are seconds away from expiring
        // to give dispatcher better insight onto when a checkin is closer to expiring

        // we will update counter, to force useEffect to fire again
        let timeoutCounter = 0
        if (Math.abs(nextCheckinSeconds) > 60) {
            timeoutCounter = 5000
        } else {
            timeoutCounter = 1000
        }
        setTimeout(() => {
            setCounter(counter + 1);
        }, timeoutCounter);

    }, [counter, dateCreated, ttlSeconds])
    return (
        <StyledPaper
            elevation={0}
            type={getCheckinDisplayType(nextCheckinSeconds, checkinType)}
        >
            <StyledCheckinHeader>{createCheckinStringValue(nextCheckinSeconds)}</StyledCheckinHeader>
            <StyledCheckinSubHeader>{nextCheckinSeconds < 0 ? "Missed" : "Next"} Check-in</StyledCheckinSubHeader>

        </StyledPaper>

    )
}