
export enum CheckinType {
    safe = "safe",
    emergency = "emergency",
    none = "none",
    life_check = "life_check"
}
export interface ICheckin {
    latitude: number
    longitude: number
    user_id: number
    group_id: number
    ttl: number
    checkin_type: CheckinType
    date_created: string
}