import { useContext, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import { WorkerContext } from "../context/workerContext"
import styled from 'styled-components'
import { CheckinType } from '../interface/checkinInterface';

const StyledDiv = styled.div`
  height: 100vh;
  width: 100%
`
const getMapOptions = (maps: any) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [{
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }]
    }],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },

    zoomControl: true,
    clickableIcons: false
  };
}

export default function GoogleMapsComponent() {
  const [center, setCenter] = useState({
    // TODO set as center of all tracked workers
    lat: 56,
    lng: 56
  });
  const [isCenterSet, setIsCenterSet] = useState(false)
  const [zoom] = useState(16);
  const { workerState, setFocusedWorker, focusedWorker } = useContext(WorkerContext)

  useEffect(() => {
    /**
   * If a worker is focused, set center of map there
   */
    const setFocusedWorkerCenter = () => {
      // check to see if focused user is set
      if (Object.keys(focusedWorker).length > 0) {
        setCenter({ lat: focusedWorker.last_checkin.latitude, lng: focusedWorker.last_checkin.longitude })
        return
      }
    }

    /**
     * This function is used to set the initial center target
     */
    const setInitialCenter = () => {
      setIsCenterSet(true)
      // First lets center on a worker with emergency status
      const emergencyWorkers = workerState.filter(worker => worker.last_checkin.checkin_type === CheckinType.emergency)
      if (emergencyWorkers.length > 0) {
        setCenter({ lat: emergencyWorkers[0].last_checkin.latitude, lng: emergencyWorkers[0].last_checkin.longitude })
        return
      }
      // next set to user on lifecheck
      const lifeCheckWorkers = workerState.filter(worker => worker.last_checkin.checkin_type === CheckinType.life_check)
      if (lifeCheckWorkers.length > 0) {
        setCenter({ lat: lifeCheckWorkers[0].last_checkin.latitude, lng: lifeCheckWorkers[0].last_checkin.longitude })
        return
      }
      // if no emergency of lifecheck workers exist, set as random
      setCenter({ lat: workerState[0].last_checkin.latitude, lng: workerState[0].last_checkin.longitude })
    }

    // Entrance to useEffect
    if (workerState.length === 0) {
      return
    }
    if (isCenterSet === false) {
      setInitialCenter()
    }

    //if there is a focused worker, then lets center the map and highlight worker
    setFocusedWorkerCenter()


  }, [workerState, isCenterSet, focusedWorker])

  return (
    <StyledDiv >
      <GoogleMapReact
        // TODO enable when app is live
        // bootstrapURLKeys={{ key: "xxxx"}}
        defaultCenter={center}
        defaultZoom={zoom}
        options={getMapOptions}
        center={center}
        onClick={(s) => {
          // first we need to clear all focused workers
          setFocusedWorker({})
        }}
      >
        {
          workerState.length > 0 ? (
            workerState.map((worker, index) => {
              return (
                <MapMarker
                  key={worker.user_id}
                  worker={worker}
                  // we need to add lat+lng to allow google-map-react to paint to map
                  lat={worker.last_checkin.latitude}
                  lng={worker.last_checkin.longitude}
                />
              )
            })
          ) : <h1>No Assigned users</h1>
        }
      </GoogleMapReact>
    </StyledDiv>
  );
}
