import { useContext } from 'react';

import { IWorker } from "../interface/workerInterface";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography';
import { GroupContext } from '../context/groupContext';
import CheckinStatusPaper from './CheckinStatusPaper';
import { WorkerContext } from '../context/workerContext';
import ProfilePictureAvatar from './ProfilePictureAvatar';
type WorkerListProps = {
    workers: IWorker[]
}
const StyledList = styled(List)`
&&&{
    width:100%;
    padding:0
}
`

const StyledListItem = styled(ListItem)`
&&&{
    width:100%;
    text-align:left;
    padding:10px;
    border-radius:0;
    height:75px

}
`

export default function WorkerList({ workers }: WorkerListProps) {
    const { getGroupFromId } = useContext(GroupContext)
    const { focusedWorker, setFocusedWorker } = useContext(WorkerContext)

    if (workers.length === 0) {
        return (<Typography>You have no active workers</Typography>)
    }

    return (
        <StyledList>
            {workers.map((worker: IWorker) => {
                return (
                    <StyledListItem
                        button
                        key={`${worker.user_id}-${worker.group_id}`}
                        onClick={() => setFocusedWorker(worker)}
                        selected={focusedWorker.user_id === worker.user_id}>
                        <ListItemAvatar>
                            <ProfilePictureAvatar profile_picture_url={worker.profile_picture_url} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${worker.first_name} ${worker.last_name}`}
                            secondary={getGroupFromId(worker.group_id).name} />
                        <ListItemSecondaryAction>
                            <CheckinStatusPaper
                                dateCreated={worker.last_checkin.date_created}
                                ttlSeconds={worker.last_checkin.ttl}
                                checkinType={worker.last_checkin.checkin_type} />
                        </ListItemSecondaryAction>
                    </StyledListItem>
                )
            })}
        </StyledList>
    )


}