import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import AWSSettings from "./aws-exports";
import "./App.css";
import { createBrowserHistory } from "history";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
// import routes
import Home from "./routes/home";
import Settings from "./routes/settings";
import Login from "./routes/login";
import DispatcherRequired from "./routes/dispatcherRequired";
import AdminOrganizationUsers from "./routes/adminOrganizationUsers";
import AdminOrganizationGroups from "./routes/adminOrganizationGroups";
import Unauthorized from "./routes/unauthorized";
// import providers
import { GroupContextProvider } from "./context/groupContext";
import { WorkerContextProvider } from "./context/workerContext";
import { AdminPanelUserContextProvider } from "./context/adminPanelUserContext";
import { AdminPanelGroupContextProvider } from "./context/adminPanelGroupContext";
import API from "@aws-amplify/api";
import { UserContext } from "./context/userContext";
import { getUserData } from "./actions/userActions";
import { UserRole } from "./interface/userInterface";
export const history = createBrowserHistory();
// configure amplify
Amplify.configure(AWSSettings.awsconfig);
API.configure(AWSSettings.awsconfig);
// configure auth callback
Auth.configure(AWSSettings.awsauth);
const theme = createMuiTheme({
  palette: {
    success: { main: "#44b700" },
    info: { main: "#f8e71c" },
    warning: { main: "#f11c78" },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "#616161",
    },
    secondary: {
      light: "#ff675",
      main: "#e82c33",
      dark: "#ae000c",
      contrastText: "#fff",
    },
    primary: {
      light: "#4a9763",
      main: "#166838",
      dark: "#003c11",
      contrastText: "#000",
    },
  },
});

function App() {
  const [isAuthenticated, setLoggedIn] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const { userDispatch } = useContext(UserContext);
  // Check to see if the user is authenticated
  async function isUserAuthenticated() {
    return await Auth.currentAuthenticatedUser()
      .then(() => {
        setLoggedIn(true);
      })
      .catch(() => {
        setLoggedIn(false);
      });
  }

  useEffect(() => {
    isUserAuthenticated();
    // get user and check to see if user is admin
    getUserData().then((res) => {
      userDispatch(res);
      if (
        [UserRole.ADMIN, UserRole.SUPER_ADMIN].indexOf(res.payload.role) === -1
      ) {
        setIsAdmin(false);
      }
    });
  }, [userDispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <>
            <WorkerContextProvider>
              <GroupContextProvider>
                <Route
                  exact
                  path="/"
                  render={() =>
                    isAuthenticated ? <Home /> : <Redirect to="/login" />
                  }
                />
              </GroupContextProvider>
            </WorkerContextProvider>
            <AdminPanelUserContextProvider>
              <AdminPanelGroupContextProvider>
                <Route
                  exact
                  path="/admin/organization-users"
                  render={() =>
                    isAdmin && isAuthenticated ? (
                      <AdminOrganizationUsers />
                    ) : (
                      <Redirect to="/unauthorized" />
                    )
                  }
                />
                <Route
                  exact
                  path="/admin/organization-groups"
                  render={() =>
                    isAdmin && isAuthenticated ? (
                      <AdminOrganizationGroups />
                    ) : (
                      <Redirect to="/unauthorized" />
                    )
                  }
                />
              </AdminPanelGroupContextProvider>
            </AdminPanelUserContextProvider>
            {/* Admin only routes */}
            <Route
              exact
              path="/dispatcher-required"
              render={() => <DispatcherRequired />}
            />
            <Route
              exact
              path="/login"
              render={() => (isAuthenticated ? <Redirect to="/" /> : <Login />)}
            />
            <Route
              exact
              path="/settings"
              render={() =>
                isAuthenticated ? <Settings /> : <Redirect to="/login" />
              }
            />
            <Route exact path="/unauthorized" render={() => <Unauthorized />} />
          </>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
