import { GROUP_ACTION_TYPES } from "../actions/groupActions";
export enum GroupStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
}

export interface IGroup {
  id: number;
  name: string;
  ttl: number;
  date_created: Date;
  last_updated: Date;
  status: GroupStatusEnum;
}

export type IGroupAction = {
  type: GROUP_ACTION_TYPES.GET_DISPATCHER_GROUPS;
  payload: IGroup[];
};

export interface IGroupModel {
  groupState: IGroup[];
  groupDispatch: React.Dispatch<IGroupAction>;
  getGroupFromId: any;
}

export interface IUserGroup {
  user_id: number;
  group_id: number;
  date_created: string;
}
