import AdminSettingsNavDrawer, { AdminSettingsNavTypes } from '../components/AdminSettingsNavDrawer';
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid';
import AdminUserTable from '../components/AdminUserTable'
import UserCreateModal from '../components/CreateUserModal';

const StyledRoot = styled.div`
display:flex
`
const StyledMain = styled.main`
flex-grow: 1;
margin-top:85px;
margin-left:15px;
margin-right:15px;
text-align:left;
`


export default function AdminOrganizationUsers() {
    return (
        <StyledRoot className="App">
            <AdminSettingsNavDrawer selected={AdminSettingsNavTypes.Users} />
            <StyledMain>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <UserCreateModal />
                    </Grid>
                    <Grid item xs={12} >
                        <AdminUserTable />
                    </Grid>
                </Grid>
            </StyledMain>
        </StyledRoot>
    );
}
