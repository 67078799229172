import React, { useState } from 'react';
import styled from 'styled-components'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Tab from '@material-ui/core/Tab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WorkerPanel from './WorkerPanel';
import Logo from '../assets/img/logo.jpg';
import UserSettingsMenuDropdown from './UserSettingsMenuDropdown'
const StyledCard = styled(Card)`
    width: 450;
    left: 20;
    top: 40;
    z-index: 1;
    position: fixed;
`

const StyledCardHeader = styled(CardHeader)`
    text-align: left;
    padding: 0
`

const StyledHeaderAction = styled.div`
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
`

const StyledCardContent = styled(CardContent)`
    &&&{
        padding-left:0px;
        padding-right:0px
    }
`

const StyledTabs = styled(Tabs)`
    width:100%
`

const StyledTab = styled(Tab)`
    min-width: 100;
    flex: 1
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}


export default function ControlPanel() {

    const [isExpanded, setIsExpanded] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleExpandDashboardClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <StyledCard variant="outlined">
            <StyledCardHeader
                avatar={<IconButton>
                    <Avatar src={Logo} ></Avatar>
                </IconButton>
                }
                action={
                    <StyledHeaderAction >
                        <UserSettingsMenuDropdown />
                        <IconButton aria-label="settings" onClick={handleExpandDashboardClick}>
                            {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </IconButton>
                    </StyledHeaderAction>
                }
            />

            <Collapse in={isExpanded} timeout="auto">
                <StyledCardContent >
                    <StyledTabs value={tabIndex} onChange={handleTabChange}>
                        <StyledTab label="Watches" />
                        <StyledTab label="Groups" />
                        <StyledTab label="Zones" />
                    </StyledTabs>
                    <TabPanel value={tabIndex} index={0}>
                        <WorkerPanel />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        Item Two
            </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        Item Three
            </TabPanel>
                </StyledCardContent>
            </Collapse>
        </StyledCard>
    );
}
