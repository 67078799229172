import { USER_ACTION_TYPES } from "../actions/userActions";

export enum UserRole {
    USER = "user",
    ADMIN = "admin",
    SUPER_ADMIN = "super_admin"

}
//Logged in user data
export interface IUser {
    organization_id: number | null
    first_name: string
    last_name: string
    cognito_sub: string
    phone_number: string | null
    company: string | null
    email: string
    id: number
    profile_picture_url: string | null
    role: UserRole
}

export type IUserAction = | { type: USER_ACTION_TYPES.GET_USER_DATA; payload: IUser }

export interface IUserModel {
    userState: IUser;
    userDispatch: React.Dispatch<IUserAction>;
    isUserAdmin: any;
    isUserSuperAdmin: any;
}