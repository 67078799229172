import React, { useContext } from 'react';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components'
import { IWorker } from '../interface/workerInterface';
import { WorkerContext } from '../context/workerContext';
import ProfilePictureAvatar from './ProfilePictureAvatar';
const StyledBadge = styled(Badge)`
${({ theme }) => `
    .MuiBadge-badge{
        background: ${theme.palette.success.main};
    }
`}

`
const UserTooltip = styled(Tooltip)`
    ${({ theme }) => `
        background-color:white;
        color:${theme.palette.text.primary}
    `}
`
const StyledIconButton = styled(IconButton)`
${({ theme }) => `
    border : 2px solid ${theme.palette.success.main};
    padding:0
    `}
`

type MarkerProps = {
    worker: IWorker
    lat: number
    lng: number
}

export default function MapMarker({ worker, lat, lng }: MarkerProps) {
    const { focusedWorker, setFocusedWorker } = useContext(WorkerContext)
    return (
        <div>
            <UserTooltip leaveDelay={1} open={worker.user_id === focusedWorker.user_id} title={
                <React.Fragment>
                    <div>
                        <Typography color="inherit" variant="body2">
                            {worker.first_name} {worker.last_name}
                        </Typography>
                        <Typography color="inherit" variant="caption">
                        </Typography>
                    </div>
                </React.Fragment>
            }>
                <StyledIconButton
                    onClick={(e) => {
                        // stop propogation stops google-maps-react from triggering
                        e.stopPropagation();
                        setFocusedWorker(worker)
                    }}
                    size="small">
                    <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="dot"
                    >
                        <ProfilePictureAvatar profile_picture_url={worker.profile_picture_url} />
                    </StyledBadge>
                </StyledIconButton>
            </UserTooltip>
        </div>
    );
}
