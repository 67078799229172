import React, { useReducer } from "react";
import { ADMIN_PANEL_GROUP_ACTION_TYPES } from "../actions/adminPanelGroupActions";
import {
  IAdminPanelGroup,
  IAdminPanelGroupAction,
  IAdminPanelGroupModel,
} from "../interface/adminPanelGroupInterface";

const defaultState: IAdminPanelGroup[] = [] as IAdminPanelGroup[];

const reducer = (
  state: IAdminPanelGroup[],
  action: IAdminPanelGroupAction
): IAdminPanelGroup[] => {
  switch (action.type) {
    case ADMIN_PANEL_GROUP_ACTION_TYPES.GET_GROUPS:
      return action.payload;
    case ADMIN_PANEL_GROUP_ACTION_TYPES.ADD_GROUP:
      return [...state, action.payload];
    case ADMIN_PANEL_GROUP_ACTION_TYPES.DELETE_GROUP_BY_ID:
      return state.filter(
        (group: IAdminPanelGroup) => group.id !== action.payload
      );
    default:
      return state;
  }
};
export const AdminPanelGroupContext = React.createContext(
  {} as IAdminPanelGroupModel
);

export const AdminPanelGroupContextProvider: React.FC = (props) => {
  const [adminPanelGroupState, adminPanelGroupDispatch] = useReducer(
    reducer,
    defaultState
  );
  const getGroupById = (id: number): IAdminPanelGroup => {
    return adminPanelGroupState.filter(
      (group: IAdminPanelGroup) => group.id === id
    )[0];
  };
  return (
    <AdminPanelGroupContext.Provider
      value={{ adminPanelGroupState, adminPanelGroupDispatch, getGroupById }}
    >
      {props.children}
    </AdminPanelGroupContext.Provider>
  );
};
