import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import styled from 'styled-components'
type ProfilePictureUserProps = {
    profile_picture_url: string | null
}
const StyledAvatar = styled(Avatar)`
&&&{
    width:150px;
    height:150px;
    margin:0 auto
}
`

const StyledAccountCircleIcon = styled(AccountCircleIcon)`
&&&{
    width:200px;
    height:200px;
}
`

export default function ProfilePictureUser({ profile_picture_url }: ProfilePictureUserProps) {
    return (
        <>
            {
                profile_picture_url ? <StyledAvatar
                    src={profile_picture_url}
                /> :
                    <StyledAvatar >
                        <StyledAccountCircleIcon />
                    </StyledAvatar>
            }
        </>
    );
}
