import React, { useContext, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import GroupIcon from '@material-ui/icons/Group';
import PortraitIcon from '@material-ui/icons/Portrait';
import styled from 'styled-components'
import BorderAllIcon from '@material-ui/icons/BorderAll';
import SettingsIcon from '@material-ui/icons/Settings';
import StreetviewIcon from '@material-ui/icons/Streetview';
import Logo from '../assets/img/logo.jpg';
import { getAdminPanelUsers } from '../actions/adminPanelUserActions';
import { AdminPanelUserContext } from '../context/adminPanelUserContext';
import { Link } from 'react-router-dom';
import { AdminPanelGroupContext } from '../context/adminPanelGroupContext';
import { getAdminPanelGroups } from '../actions/adminPanelGroupActions';

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`
const drawerWidth = "240px"
const StyledDrawer = styled(Drawer)`
width: ${drawerWidth};
flex-shrink:0
`

const StyledDrawerContainer = styled.div`
overflow: auto;
width:${drawerWidth}
`
const StyledAppBar = styled(AppBar)`
${({ theme }) => `
&&&{
  z-index:${theme.zIndex.modal};
  background:#fff;
  border-bottom:2px solid ${theme.palette.secondary.main}
  }
`}
`

const StyledHeaderTypography = styled(Typography)`
  ${({ theme }) => `
  &&&{
  color:${theme.palette.text.primary}
  }
  `}
`
const StyledLogo = styled.img`
max-width:45px;
margin-right:10px
`
const StyledListItemIcon = styled(ListItemIcon)`
  &&&{
    min-width:40px
  }
`
export enum AdminSettingsNavTypes {
  Users,
  Groups,
  Zones,
  Settings
}
type AdminSettingsNavDrawerProps = {
  selected: AdminSettingsNavTypes
}

export default function AdminSettingsNavDrawers({ selected }: AdminSettingsNavDrawerProps) {

  const { adminPanelUserDispatch } = useContext(AdminPanelUserContext)
  const { adminPanelGroupDispatch } = useContext(AdminPanelGroupContext)

  useEffect(() => {
    // Get all the users belonging to this org
    getAdminPanelUsers().then(res => adminPanelUserDispatch(res))
    getAdminPanelGroups().then(res => adminPanelGroupDispatch(res))
  }, [adminPanelUserDispatch, adminPanelGroupDispatch])
  return (
    <>
      <StyledAppBar
        position="fixed"
      >
        <Toolbar>
          <>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeaderTypography variant="h5" noWrap>
              Admin Panel
          </StyledHeaderTypography>
          </>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer
        variant="permanent">
        <Toolbar />
        <StyledDrawerContainer >
          <List>
            <StyledLink to="/admin/organization-users">
            <ListItem button key='user' selected={selected === AdminSettingsNavTypes.Users}>
              <StyledListItemIcon>
                <PortraitIcon />
              </StyledListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </StyledLink>
          <StyledLink to="/admin/organization-groups">
            <ListItem button key='groups' selected={selected === AdminSettingsNavTypes.Groups}>
              <StyledListItemIcon>
                <GroupIcon />
              </StyledListItemIcon>
              <ListItemText primary="Groups" />
            </ListItem>
          </StyledLink>
            <ListItem button key='zones' selected={selected === AdminSettingsNavTypes.Zones}>
              <StyledListItemIcon>
                <BorderAllIcon />
              </StyledListItemIcon>
              <ListItemText primary="Zones" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key='org-setting' selected={selected === AdminSettingsNavTypes.Settings}>
              <StyledListItemIcon>
                <SettingsIcon />
              </StyledListItemIcon>
              <ListItemText primary="Organization Settings" />
            </ListItem>
            <StyledLink to="/">
              <ListItem button key='dispatcherview'>
                <StyledListItemIcon>
                  <StreetviewIcon />
                </StyledListItemIcon>
                <ListItemText primary="Dispatcher View" />
              </ListItem>
            </StyledLink>
          </List>
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
}
