import { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeAPICall, APIMethods } from "../utils";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

//import components
import GoogleMapsComponent from "../components/GoogleMapsComponent";
import ControlPanel from "../components/ControlPanel";
// import context
import { GroupContext } from "../context/groupContext";
import { WorkerContext } from "../context/workerContext";
// import interfaces
import { IGroupAction } from "../interface/groupInterface";
import { IWorker, IWorkerAction } from "../interface/workerInterface";
// import actions
import { getDispatcherGroups } from "../actions/groupActions";
import {
  getAPIWorkerDataFromCheckin,
  getMontitoredWorkersFromGroupId,
  subscribeToCreateGroupId,
  subscribeToUpdateGroupId,
  subscribeToDeleteGroupId,
  WORKER_ACTION_TYPES,
} from "../actions/workerActions";

const StyledGrid = styled(Grid)`
    flex-grow:1,
    height:100%
`;
function Home() {
  const { groupDispatch } = useContext(GroupContext);
  const { workerDispatch } = useContext(WorkerContext);

  useEffect(() => {
    // initialize data
    async function loadInitialData(dispatcherId: number) {
      // Load user data and dispatch data
      // get all the groups that the dispatcher is assigned to and dispatch action to update state
      const dispatcherGroupData = await getDispatcherGroups(
        dispatcherId as number
      ).then((res: IGroupAction) => {
        groupDispatch(res);
        return res.payload;
      });
      // group group ids that dispatchert is assisnged to
      const dispatcherGroupIds = dispatcherGroupData.map((x) => x.id);
      // query graphql to get all monitored users for that dispatcher
      getMontitoredWorkersFromGroupId(dispatcherGroupIds).then(
        (res: IWorkerAction) => {
          workerDispatch(res);
        }
      );
      // subscribe to all update events in user groups to receive changes
      for (const groupId of dispatcherGroupIds) {
        subscribeToUpdateGroupId(groupId).subscribe({
          next: (payload) => {
            return workerDispatch({
              type: WORKER_ACTION_TYPES.UPDATE_MONITORED_USER_CHECKIN,
              payload: payload.value.data.onUpdateCheckinStatus,
            });
          },
        });
        //subscribe to all create events for group
        subscribeToCreateGroupId(groupId).subscribe({
          next: (payload) => {
            // on create we need to get user data
            getAPIWorkerDataFromCheckin([
              payload.value.data.onCreateCheckinStatus,
            ]).then((res: IWorker[]) => {
              return workerDispatch({
                type: WORKER_ACTION_TYPES.CREATE_MONITORED_USER,
                payload: res[0],
              });
            });
          },
        });
        //  subscribe to all delete events for group
        subscribeToDeleteGroupId(groupId).subscribe({
          next: (payload) => {
            return workerDispatch({
              type: WORKER_ACTION_TYPES.DELETE_MONITORED_USER,
              payload: payload.value.data.onDeleteCheckinStatus,
            });
          },
        });
      }
    }

    // check if user is dispatcher first. If user not dispatcher, redirect to restricted
    makeAPICall("/dispatchers", APIMethods.GET, null)
      .then((res) => {
        if (res.status === 200) {
          // load data with dispatcher id
          loadInitialData(res.data[0].id);
        } else {
          return <Redirect to="/login" />;
        }
      })
      .catch((err) => {
        return <Redirect to="/login" />;
      });
  }, [groupDispatch, workerDispatch]);

  return (
    <div className="App">
      <StyledGrid container spacing={2}>
        <ControlPanel />
        <Grid item xs={12}>
          <GoogleMapsComponent />
        </Grid>
      </StyledGrid>
    </div>
  );
}

export default Home;
