import { IGroupAction } from "../interface/groupInterface";
import { makeAPICall, APIMethods } from "../utils";

export enum GROUP_ACTION_TYPES {
  GET_DISPATCHER_GROUPS = "GET_DISPATCHER_GROUPS",
}
/**
 *
 * @returns IUserAction
 */
export const getDispatcherGroups = async (
  dispatcherId: number
): Promise<IGroupAction> => {
  // first get all the groups that this dispatcher is assigned to
  const dispatcherGroups = await makeAPICall(
    `/dispatchers/${dispatcherId}/groups`,
    APIMethods.GET,
    null
  );
  //go through all the groups and grab group data
  return {
    type: GROUP_ACTION_TYPES.GET_DISPATCHER_GROUPS,
    payload: dispatcherGroups.data,
  };
};
