import { IAdminPanelUser, IAdminPanelUserAction, IEditAdminPanelUser } from "../interface/adminPanelUserInterface"
import { APIMethods, makeAPICall } from "../utils"

export enum ADMIN_PANEL_USER_ACTION_TYPES {
    GET_USERS = "GET_USERS",
    ADD_USER = "ADD_USER",
    UPDATE_USER = "UPDATE_USER"
}

/**
 * This function returns the users of the organization
 */
export const getAdminPanelUsers = async (): Promise<IAdminPanelUserAction> => {
    const userData = await makeAPICall('/admin/users', APIMethods.GET, null)
    return {
        type: ADMIN_PANEL_USER_ACTION_TYPES.GET_USERS,
        payload: userData.data
    }

}


/**
 * This function adds users to the admin panel
 */
export const addUserToAdminPanel = (adminUser: IAdminPanelUser): IAdminPanelUserAction => {
    return {
        type: ADMIN_PANEL_USER_ACTION_TYPES.ADD_USER,
        payload: adminUser
    }

}

export const updateAdminPanelUser = (adminUser: IEditAdminPanelUser): IAdminPanelUserAction => {
    return {
        type: ADMIN_PANEL_USER_ACTION_TYPES.UPDATE_USER,
        payload: adminUser
    }
}
